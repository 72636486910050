import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, ref } from 'vue';
import { moo } from "@/api/restful";
import { useRoute, useRouter } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button } from "vant";
export default {
  name: "MooOrderCancel",
  components: {
    [Button.name]: Button
  },
  methods: {
    clickButton: function () {
      var instance = this;
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 5
      });
      moo.orderCancel(this.query.id, this.reason).then(res => {
        if (res.data.code == 0) {
          dsb.mgo("setLoading", {
            "act": 0
          });
          dsb.mgo("removeCtr", {
            "name": ["serviceInfo", "serviceOrder", "OrderInfo"]
          });
          instance.router.push({
            name: 'MooOrderInfo',
            query: this.query
          });
        }
      });
    },
    clickSelect: function () {
      var instance = this;
      dsb.mgo("select", {
        "content": ["预约时间过长", "预约信息填写错误", "技师表示无法到达", "其他原因"],
        "default": instance.reasonStr
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.reasonStr = res.str;
        instance.reason = res.index;
        console.log(instance.reason);
      });
    }
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      reasonStr: "",
      reason: 0,
      router: useRouter(),
      query: {}
    });

    // 调取api获取数据
    const getdata = () => {};
    state.query = route.query;
    onMounted(getdata);
    const activeName = ref('0');
    return {
      ...toRefs(state),
      activeName
    };
  }
};