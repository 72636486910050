export default {
  props: {
    avatar: {
      type: String,
      default: ""
    },
    bgimg: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  }
};