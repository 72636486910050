import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_cell = _resolveComponent("cell");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, {
    "theme-vars": $setup.themeVars
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, item => {
      return _openBlock(), _createBlock(_component_van_cell_group, {
        border: false,
        key: item
      }, {
        default: _withCtx(() => [_createVNode(_component_cell, {
          title: item.label,
          change: parseInt(item.change / 10),
          date: item.date
        }, null, 8, ["title", "change", "date"])]),
        _: 2
      }, 1024);
    }), 128))])]),
    _: 1
  }, 8, ["theme-vars"]);
}