export default {
  name: 'CzOption',
  props: {
    os: {
      type: String,
      default: () => "ios"
    },
    jg: {
      type: Number,
      default: () => 12
    },
    picked: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {},
  watch: {},
  data: function () {
    return {
      pick: this.picked
    };
  }
};