
import { request } from './request-fly'
import utils from '@/lib/utils.js';

export class base {
    static async req(api,payload,version=1,isDSB = true) {
        //获取APP设置
        
        if (payload.params.uid == "") {
            payload.params.uid = utils.getUrlKey('uid',"")
        }
        if (payload.params.token == "") {
            payload.params.token = utils.getUrlKey('token',"")
        }
        return request(version.toString()+"/"+api,JSON.stringify(payload), 'POST',isDSB)
    }
}




export class userdata {    
    
    static async blackList(params) {
        /**
         * @description 获取地列表
         * @return {HttpResponse} result
         */
        params["loading"] = 1
        const payload = {
            params:params
        }
        return request("1/user_h5_blacklist",JSON.stringify(payload), 'POST')
    }

    static async blackListSet(uid,token,tuid,set) {
        /**
         * @description 获取地列表
         * @return {HttpResponse} result
         */
        const payload = {
            params:{
                "uid":uid,
                "tuid":tuid,
                "token":token,
                "set":set
            }
        }
        
        return request("1/user_req_blacklist",JSON.stringify(payload), 'POST')
    }

    static async appconfigData(uid,token) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return request("1/user_h5_appconfig",JSON.stringify(payload), 'POST')
    }

    static async appconfigSet(uid,token,pushTimeLimit,isMute,imDetail,badge) {
        //更新APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "pushTimeLimit":pushTimeLimit,
                "isMute":isMute,
                "imDetail":imDetail,
                "badge":badge
            }
        }
        return request("1/user_req_appconfig",JSON.stringify(payload), 'POST')
    }

    static async profileInfo(uid,token) {
        //获取APP设置 h5
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return request("1/user_h5_profile",JSON.stringify(payload), 'POST')
    }

    static async profileModify(uid,token,nickname,avatar,birthday) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "nickname":nickname,
                "avatar":avatar,
                "birthday":birthday
            }
        }
        return request("1/user_req_profile_modify",JSON.stringify(payload), 'POST')
    }


    
}



export class photoData {    
    
    static async photoManager(uid,token) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return request("1/photo_h5_manager",JSON.stringify(payload), 'POST')
    }

    static async photoAdd(uid,token,url) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "photo":url
            }
        }
        return request("1/photo_req_insert",JSON.stringify(payload), 'POST')
    }

    static async photoRemove(uid,token,photoId) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "photoId":photoId
            }
        }
        return request("1/photo_req_remove",JSON.stringify(payload), 'POST')
    }
}

export class ayoInfo {    
    
    static async applyData(uid,token) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return request("1/ayo_h5_apply",JSON.stringify(payload), 'POST')
    }

    static async applyDo(uid,token,params) {
        //获取APP设置
        params["uid"] = uid
        params["token"] = token
        const payload = {
            params:params
        }
        return request("1/ayo_req_apply",JSON.stringify(payload), 'POST')
    }

    static async pageInfo(uid,token,tuid) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "tuid":tuid
            }
        }
        return request("1/ayo_h5_page",JSON.stringify(payload), 'POST')
    }

    static async like(uid,token,tuid,like) {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "tuid":tuid,
                "like":like
            }
        }
        return request("1/ayo_req_like",JSON.stringify(payload), 'POST')
    }

    static async photoList(uid,token,tuid) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "tuid":tuid
            }
        }
        return request("1/ayo_h5_photo",JSON.stringify(payload), 'POST')
    }
}




export class ectinfo {    
    
    static async helpcenterData() {
        //无参数
        var params = {}
        params["loading"] = 1
        const payload = {
            params:params
        }
        return request("1/ect_h5_helpcenter",JSON.stringify(payload), 'POST')
    }

    static async rmarticleInfo(rmid) {
        //rmid文章id
        const payload = {
            params:{
                "rmid":rmid
            }
        }
        return request("1/ect_h5_rm",JSON.stringify(payload), 'POST')
    }

}


export class yo {    
    
    static async stickCreatePage(uid,token) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
            }
        }
        return request("1/stick_h5_create",JSON.stringify(payload), 'POST')
    }

    static async stickInfo(uid,token,id) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "id":id,
            }
        }
        return request("1/stick_h5_info",JSON.stringify(payload), 'POST')
    }

    static async stickApply(uid,token,data) {
        //获取APP设置
        data["uid"] = uid
        data["token"] = token
        const payload = {
            params:data
        }
        return request("1/stick_req_create",JSON.stringify(payload), 'POST')
    }

    static async stickLike(uid,token,id,like) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "id":id,
                "like":like,
            }
        }
        return request("1/stick_req_like",JSON.stringify(payload), 'POST')
    }

    static async stickClose(uid,token,id) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "id":id,
            }
        }
        return request("1/stick_req_close",JSON.stringify(payload), 'POST')
    }

    static async stickBoost(uid,token,id,days) {
        //获取APP设置
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "id":id,
                "days":days
            }
        }
        return request("1/stick_req_boost",JSON.stringify(payload), 'POST')
    }
}

export class qb { 

    static async qbInfo(uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return base.req("qb_h5_info",payload)
    }

    static async withdrawInfo(uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return base.req("qb_h5_withdraw",payload)
    }


    static async qbTk(type,amount,account="",bank="",uid="",token="") {
        const payload = {
            params:{
                "type":type,
                "amount":amount,
                "account":account,
                "bank":bank,
                "uid":uid,
                "token":token
            }
        }
        return base.req("qb_req_tk",payload)
    }

    

    static async qbZs(uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return base.req("qb_h5_zs",payload)
    }

    static async qbZd(uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return base.req("qb_h5_zd",payload)
    }

    static async qbCz(type,uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "type":type
            }
        }
        return base.req("qb_h5_cz",payload)
    }
}


export class member {    
    
    static async memberInfo(uid,token) {
        //无参数
        const payload = {
            params:{
                "uid":uid,
                "token":token,
            }
        }
        return request("1/member_h5_info",JSON.stringify(payload), 'POST')
    }


    static async memberIapCheck(receipt,appinfo,uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "receipt":receipt
            },
            commonInfo:appinfo
        }
        return base.req("iap_req_check",payload,1,false)
    }
}


export class captcha {    
    
    static async codeSend(action,type,sendto) {
        const payload = {
            params:{
                "action":action,
                "type":type,
                "sendto":sendto,
            }
        }
        return request("1/captcha_req_code",JSON.stringify(payload), 'POST')
    }
}



export class moo {    
    
    static async service_info(id,tuid,uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "id":id,
                "tuid":tuid
            }
        }
        return base.req("service_h5_info",payload)
    }

    static async service_order(id,uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "id":id
            }
        }
        return base.req("service_h5_order",payload)
    }
    
    static async customer_prior_set(tuid,set,uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "tuid":tuid,
                "set":set
            }
        }
        console.log(payload)
        return base.req("customer_req_prior_set",payload)
    }

    static async customer_prior_list(uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return base.req("customer_req_prior_list",payload)
    }


    static async order_create(serviceId,
                            tuid,
                            address,
                            customerGender,
                            therapistGenderFlag,
                            prior,
                            availableUnixTime,
                            orderTimeOutUnixTime,
                            remark,
                            uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token,
                "tuid":tuid,
                "address":address,
                "serviceId":serviceId,
                "customerGender":customerGender,
                "therapistGenderFlag":therapistGenderFlag,
                "prior":prior,
                "availableUnixTime":availableUnixTime,
                "orderTimeOutUnixTime":orderTimeOutUnixTime,
                "remark":remark
            }
        }
        return base.req("order_req_create",payload)
    }


    static async orderInfo(id,uid="",token="") {
        const payload = {
            params:{
                "id":id,
                "uid":uid,
                "token":token
            }
        }
        return base.req("order_h5_info",payload)
    }

    static async orderStatus(id,uid="",token="") {
        const payload = {
            params:{
                "id":id,
                "uid":uid,
                "token":token
            }
        }
        return base.req("order_req_status",payload)
    }

    static async orderCancel(id,reason,uid="",token="") {
        const payload = {
            params:{
                "id":id,
                "reason":reason,
                "uid":uid,
                "token":token
            }
        }
        return base.req("order_req_cancel",payload)
    }

    static async orderStart(id,uid="",token="") {
        const payload = {
            params:{
                "id":id,
                "uid":uid,
                "token":token
            }
        }
        return base.req("order_req_start",payload)
    }

    static async therapistConfigInfo(uid="",token="") {
        const payload = {
            params:{
                "uid":uid,
                "token":token
            }
        }
        return base.req("therapist_h5_config",payload)
    }

    static async therapistConfigSet(id=null,onTime=null,uid="",token="") {

        var params = {
            "uid":uid,
            "token":token
        }
        if(id != null){
            params["id"] = id
        }
        if(onTime != null){
            params["onTime"] = onTime
        }
        return base.req("therapist_req_config",{params:params})
    }

    static async therapistNearlist(gender,prior,city,uid="",token="") {

        var params = {
            "uid":uid,
            "token":token,
            "gender":gender,
            "prior":prior,
            "city":city
        }
        return base.req("therapist_req_nearlist",{params:params})
    }


    static async therapistConfigSetPhone(phone,code,uid="",token="") {

        var params = {
            "phone":phone,
            "code":code,
            "uid":uid,
            "token":token
        }
        return base.req("therapist_req_config_phone",{params:params})
    }
}


