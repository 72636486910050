import { onMounted, reactive, toRefs, ref } from 'vue';
import { moo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button } from "vant";
export default {
  name: "MooTherapistPrior",
  components: {
    [Button.name]: Button
  },
  methods: {
    removePrior: function (uid) {
      moo.customer_prior_set(uid, 0);
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].uid == uid) {
          this.list.splice(i, 1);
          dsb.mgo("toast", {
            "word": "移除成功"
          });
          break;
        }
      }
    },
    getTimeStr: function (time) {
      var date = new Date(time * 1000);
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      var D = date.getDate() + ' ';
      return M + D;
    }
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      list: []
    });

    // 调取api获取数据
    const getdata = () => {
      moo.customer_prior_list().then(res => {
        if (res.code == 0) {
          state.list = res.res.list;
        }
      });
    };
    state.query = route.query;
    onMounted(getdata);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    const activeName = ref('0');
    return {
      ...toRefs(state),
      themeVars,
      activeName
    };
  }
};