import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d5070fbe"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex-row list-item"
};
const _hoisted_2 = {
  class: "flex-col items-center image-box"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "flex-col items-start labelContent"
};
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "des"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    src: $props.image,
    class: "image_3"
  }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("span", _hoisted_5, _toDisplayString($props.title), 1), _createElementVNode("span", _hoisted_6, _toDisplayString($props.des), 1)])]);
}