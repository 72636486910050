import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, photo => {
    return _openBlock(), _createBlock(_component_van_image, {
      style: {
        "margin-bottom": "0.5rem"
      },
      width: "100%",
      key: photo,
      src: photo,
      "lazy-load": ""
    }, {
      loading: _withCtx(() => [_createVNode(_component_van_loading, {
        type: "spinner",
        size: "20"
      })]),
      _: 2
    }, 1032, ["src"]);
  }), 128);
}