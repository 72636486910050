import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-63e0ecae"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "margin-top": "20px"
  }
};
const _hoisted_2 = {
  style: {
    "padding-left": "0.2rem",
    "padding-top": "0.1rem"
  },
  class: "label_title"
};
const _hoisted_3 = {
  style: {
    "padding-left": "0.2rem"
  }
};
const _hoisted_4 = {
  style: {
    "padding": "0 0.1rem 0 0.1rem"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_row = _resolveComponent("van-row");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createBlock(_component_van_config_provider, {
    "theme-vars": $setup.themeVars
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_row, null, {
      default: _withCtx(() => [_createVNode(_component_van_col, {
        span: "4",
        offset: "1"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_image, {
          src: _ctx.avatar,
          width: "56px",
          height: "56px",
          round: "",
          "lazy-load": "",
          class: "aligncenter"
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 1
        }, 8, ["src"])]),
        _: 1
      }), _createVNode(_component_van_col, {
        span: "12"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.nickname), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tag_list, item => {
          return _openBlock(), _createBlock(_component_van_button, {
            type: "primary",
            size: "mini",
            round: "",
            key: item,
            color: '#' + item.color
          }, {
            default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1)]),
            _: 2
          }, 1032, ["color"]);
        }), 128))])]),
        _: 1
      }), _createVNode(_component_van_col, {
        span: "5",
        offset: "1"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_row, {
          align: "center",
          justify: "center",
          style: {
            "height": "100%",
            "width": "100%",
            "text-align": "right"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_van_col, {
            span: "24"
          }, {
            default: _withCtx(() => [_createVNode(_component_van_button, {
              type: "primary",
              size: "small",
              round: "",
              color: "var(--van-app-gender)"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.eventType), 1)]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_van_row, null, {
      default: _withCtx(() => [_createVNode(_component_van_col, {
        span: "22",
        offset: "1",
        style: {
          "word-wrap": "break-word",
          "font-size": "0.3rem"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.words), 1)]),
        _: 1
      })]),
      _: 1
    }), _ctx.photo.length == 1 ? (_openBlock(), _createBlock(_component_van_row, {
      key: 0,
      style: {
        "margin-top": "0.3rem"
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photo.slice(0, 1), item => {
        return _openBlock(), _createBlock(_component_van_col, {
          span: "22",
          offset: "1",
          key: item
        }, {
          default: _withCtx(() => [_createVNode(_component_van_image, {
            src: item,
            width: "4rem",
            height: "4rem",
            fit: "cover",
            style: {
              "border-radius": "0.2rem",
              "overflow": "hidden"
            },
            "lazy-load": ""
          }, {
            loading: _withCtx(() => [_createVNode(_component_van_loading, {
              type: "spinner",
              size: "20"
            })]),
            _: 2
          }, 1032, ["src"])]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    })) : (_openBlock(), _createBlock(_component_van_row, {
      key: 1,
      style: {
        "margin-top": "0.3rem"
      }
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photo.slice(0, 3), item => {
        return _openBlock(), _createBlock(_component_van_col, {
          span: "6",
          offset: "1",
          key: item
        }, {
          default: _withCtx(() => [_createVNode(_component_van_image, {
            src: item,
            width: "2.1rem",
            height: "2.1rem",
            fit: "cover",
            style: {
              "border-radius": "0.2rem",
              "overflow": "hidden"
            },
            "lazy-load": ""
          }, {
            loading: _withCtx(() => [_createVNode(_component_van_loading, {
              type: "spinner",
              size: "20"
            })]),
            _: 2
          }, 1032, ["src"])]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    })), _createVNode(_component_van_row, {
      align: "center",
      justify: "center",
      style: {
        "margin-top": "0.1rem",
        "text-align": "center"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_van_col, {
        span: "24"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_button, {
          type: "primary",
          size: "small",
          round: "",
          color: "var(--van-app-gender)",
          onClick: _cache[0] || (_cache[0] = $event => $options.photoShow())
        }, {
          default: _withCtx(() => [_createTextVNode("查看所有")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_van_row, {
      style: {
        "margin-top": "0.2rem"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_van_col, {
        span: "22",
        offset: "1",
        class: "label_title"
      }, {
        default: _withCtx(() => [_createTextVNode(" 活动信息 ")]),
        _: 1
      }), _createVNode(_component_van_col, {
        span: "22",
        offset: "1",
        class: "label_info"
      }, {
        default: _withCtx(() => [_createTextVNode(" 时间：" + _toDisplayString(_ctx.eventDateStr) + "，预计花费时间" + _toDisplayString(_ctx.duration), 1)]),
        _: 1
      }), _createVNode(_component_van_col, {
        span: "22",
        offset: "1",
        class: "label_info"
      }, {
        default: _withCtx(() => [_createTextVNode(" 要求：" + _toDisplayString(_ctx.gender_flag == 3 ? '不限性别' : _ctx.gender_flag == 2 ? '仅邀请女性' : '仅邀请男性'), 1)]),
        _: 1
      }), _createVNode(_component_van_col, {
        span: "22",
        offset: "1",
        class: "label_info"
      }, {
        default: _withCtx(() => [_createTextVNode(" 地点：" + _toDisplayString(_ctx.posStr), 1)]),
        _: 1
      }), _createVNode(_component_van_col, {
        span: "10",
        offset: "1",
        class: "label_info"
      }, {
        default: _withCtx(() => [_createTextVNode(" 距离：" + _toDisplayString(_ctx.distance), 1)]),
        _: 1
      }), _ctx.map_image ? (_openBlock(), _createBlock(_component_van_col, {
        key: 0,
        span: "11",
        offset: "1",
        class: "label_info",
        style: {
          "text-align": "right",
          "color": "var(--van-gray-5)"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(" 点击地图查看详细"), _createVNode(_component_van_icon, {
          name: "location"
        })]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.map_image ? (_openBlock(), _createBlock(_component_van_col, {
        key: 1,
        span: "22",
        offset: "1",
        class: "label_info"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_image, {
          src: _ctx.map_image,
          fit: "cover",
          style: {
            "border-radius": "0.2rem",
            "overflow": "hidden"
          },
          "lazy-load": "",
          onClick: _cache[1] || (_cache[1] = $event => $options.adrShow())
        }, {
          loading: _withCtx(() => [_createVNode(_component_van_loading, {
            type: "spinner",
            size: "20"
          })]),
          _: 1
        }, 8, ["src"])]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    })])]),
    _: 1
  }, 8, ["theme-vars"]);
}