import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-eb555120"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  style: {
    "font-size": "var(--van-app-font-size-1)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_1, _toDisplayString($props.title), 1)]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass([{
        selected: index == this.pick
      }, "option"]),
      key: item,
      onClick: $event => $options.clickOption(index),
      ref_for: true,
      ref: "op"
    }, [_createElementVNode("p", _hoisted_4, _toDisplayString(item), 1)], 10, _hoisted_3);
  }), 128))])], 64);
}