import { onMounted, reactive, toRefs } from 'vue';
import { ectinfo } from "../../api/restful";
import { useRoute } from "vue-router";
import dsb from "../../lib/dsbridge";
var route;
export default {
  name: "HelpCenter",
  components: {},
  methods: {
    clicArticle: function (rmid, title) {
      dsb.mgo("h5api", {
        "_title": title
      }, window.location.protocol + "//" + window.location.host + "/" + route.params.version + "/RmArticle?rmid=" + rmid + "&title=" + encodeURIComponent(title));
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      hotlist: [],
      helplist: []
    });
    // 调取api获取数据
    const getdata = () => {
      ectinfo.helpcenterData().then(res => {
        if (res.data.code == 0) {
          state.hotlist = res.data.result.hotlist;
          state.helplist = res.data.result.helplist;
          dsb.h5config(res.data.result.h5);
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};