import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67e13a74"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "des"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
    style: {
      "width": "1.5rem",
      "height": "1.5rem"
    },
    src: $props.icon
  }, null, 8, _hoisted_2), _createElementVNode("p", _hoisted_3, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_4, _toDisplayString($props.des), 1)]);
}