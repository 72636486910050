import { onMounted, reactive, toRefs } from 'vue';
import { userdata } from "../../api/restful";
import { useRoute } from "vue-router";
import dsb from "../../lib/dsbridge";
var route;
export default {
  name: "UserBlacklist",
  components: {},
  methods: {
    removeBlacklist: function (uid) {
      userdata.blackListSet(route.query.uid, route.query.token, uid, 0).then(res => {
        console.log(res);
      });
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].uid == uid) {
          this.list.splice(i, 1);
          dsb.mgo("toast", {
            "word": "已经移除"
          });
          break;
        }
      }
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      list: []
    });
    // 调取api获取数据
    const getdata = () => {
      userdata.blackList(route.query).then(res => {
        if (res.data.code == 0) {
          state.list = res.data.result.list;
          dsb.h5config(res.data.result.h5);
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};