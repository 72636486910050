import { onMounted, reactive, toRefs } from 'vue';
import { ectinfo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Skeleton } from 'vant';
var route;
export default {
  name: "RmArticle",
  components: {
    [Skeleton.name]: Skeleton
  },
  methods: {
    clicArticle: function (rmid) {
      console.log(rmid);
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      rmcontent: "",
      loading: true
    });
    // 调取api获取数据

    //document.body.style.backgroundColor="#f2f5f7";
    const getdata = () => {
      ectinfo.rmarticleInfo(route.query.rmid).then(res => {
        if (res.data.code == 0) {
          if (route.query.title != "" && res.data.result.h5) {
            if (res.data.result.h5.title) {
              res.data.result.h5.title = [route.query.title, route.query.title];
            }
          }
          dsb.h5config(res.data.result.h5);
          state.loading = false;
          const edjsHTML = require("editorjs-html");
          const edjsParser = edjsHTML();
          const htmlcontent = edjsParser.parse(res.data.result.content);
          for (var i in htmlcontent) {
            state.rmcontent += htmlcontent[i];
          }
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};