export default {
  name: "EventBlock",
  props: {
    list: {
      default: () => []
    }
  },
  components: {},
  methods: {},
  data: function () {
    return {};
  },
  watch: {}
};