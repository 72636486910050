import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs } from 'vue';
import { yo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { formatDateTime } from "@/lib/tool";
import { Button } from "vant";
var route;
export default {
  name: "YoInfo",
  components: {
    [Button.name]: Button
  },
  methods: {
    photoShow: function () {
      var photolist = [];
      for (let i in this.photo) {
        photolist.push({
          url: this.photo[i],
          type: 'image',
          cover: ''
        });
      }
      dsb.mgo("mediaBrowser", {
        "data": photolist,
        "start": 0,
        "direct": 0
      });
    },
    adrShow: function () {
      dsb.mgo("showAdr", {
        "lat": this.posDict.lat,
        "lon": this.posDict.lon,
        "title": this.posDict.name,
        "des": this.posDict.adr,
        "icon": this.avatar
      });
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      pickType_list: {},
      eventType: "",
      words: "",
      posStr: "",
      posDict: null,
      eventDate: 0,
      eventDateStr: "",
      duration: "2小时",
      anonymous: false,
      photo: [],
      query: {},
      waitforreview: false,
      gender_flag: 3,
      distance: "36km",
      map_image: "",
      //用户信息
      avatar: "",
      nickname: "",
      tag_list: [],
      imMink: {}
    });
    dsb.dsBridge.register('toolbarClick_like', function () {
      yo.stickLike(route.query.uid, route.query.token, route.query.id, '1').then(res => {
        dsb.h5config(res.data.result.h5);
        dsb.mgo("toast", {
          "word": "已通知对方",
          "duration": "1000"
        });
      });
      return "";
    });
    dsb.dsBridge.register('toolbarClick_boost', function () {
      dsb.mgo("select", {
        "content": ["1天(210友币)", "2天(410友币)", "3天(600友币)", "一周(1200友币)"]
      }, "", res => {
        var v = JSON.parse(res);
        var day = 1;
        if (v.index == 1) {
          day = 2;
        } else if (v.index == 2) {
          day = 3;
        } else if (v.index == 3) {
          day = 7;
        }
        yo.stickBoost(route.query.uid, route.query.token, route.query.id, day).then(res => {
          if (res.msg == "") {
            dsb.mgo("alert", {
              "title": "提示",
              "word": "操作成功。",
              "bt1": "确定"
            }, "", function () {
              dsb.mgo("refresh", {});
            });
          } else {
            dsb.mgo("alert", {
              "title": "提示",
              "word": res.msg,
              "bt1": "确定"
            });
          }
        });
      });
      return "";
    });
    dsb.dsBridge.register('toolbarClick_cancel', function () {
      dsb.mgo("alert", {
        "title": "操作",
        "word": "是否关闭邀请，关闭后将无法恢复。",
        "bt0": "考虑一下",
        "bt1": "关闭"
      }, "", function (res) {
        var v = JSON.parse(res);
        if (v.button == 1) {
          yo.stickClose(route.query.uid, route.query.token, route.query.id).then(res => {
            if (res.data.code == 0) {
              dsb.mgo("alert", {
                "title": "提示",
                "word": "操作成功。",
                "bt1": "确定"
              }, "", function () {
                dsb.mgo("mustRefresh", {
                  "index": 1
                });
                dsb.mgo("back", {
                  "ts": 2
                });
              });
            }
          });
        }
      });
      return "";
    });
    dsb.dsBridge.register('toolbarClick_modify', function () {
      dsb.h5({}, route.params.version, "YoCreate", {
        "uid": route.query.uid,
        "token": route.query.token
      });
      return "";
    });
    dsb.dsBridge.register('toolbarClick_unlike', function () {
      yo.stickLike(route.query.uid, route.query.token, route.query.id, '0').then(res => {
        dsb.h5config(res.data.result.h5);
        dsb.mgo("toast", {
          "word": "已取消",
          "duration": "1000"
        });
      });
      return "";
    });

    // 调取api获取数据
    const getdata = () => {
      yo.stickInfo(route.query.uid, route.query.token, route.query.id).then(res => {
        if (res.data.code == 0) {
          state.avatar = res.data.result.avatar;
          state.nickname = res.data.result.nickname;
          state.tag_list = res.data.result.tag_list;
          state.eventType = res.data.result.type;
          state.words = res.data.result.words;
          state.imMink = res.data.result.imMink;
          if (res.data.result.gender == 2) {
            themeVars.appGender = 'var(--van-app-female)';
          }
          console.log(res.data.result);
          if (res.data.result.poi.name) {
            state.posDict = res.data.result.poi;
            state.posStr = res.data.result.poi.name;
          }
          if (res.data.result.events_time != 0) {
            state.eventDate = res.data.result.events_time;
            state.eventDateStr = formatDateTime(res.data.result.events_time);
          }
          switch (res.data.result.events_duration) {
            case 1:
              state.duration = "1小时";
              break;
            case 0:
            case 2:
              state.duration = "2小时";
              break;
            case 3:
              state.duration = "3小时";
              break;
            default:
              state.duration = "4小时或更多";
          }
          state.gender_flag = res.data.result.gender_flag;
          state.anonymous = res.data.result.public == 0 ? true : false;
          state.map_image = res.data.result.map_image;
          for (var i in res.data.result.photo) {
            state.photo.push(res.data.result.photo[i]);
          }
          dsb.h5config(res.data.result.h5);
          dsb.dsBridge.register('imClick', function () {
            dsb.mink(state.imMink);
            return "";
          });
        }
      });
    };
    state.query = route.query;
    state.query.version = route.params.version;
    onMounted(getdata);
    console.log(route.query);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    return {
      ...toRefs(state),
      themeVars
    };
  }
};