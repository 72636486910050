import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f0e2cd46"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "banner"
};
const _hoisted_2 = {
  style: {
    "padding": "0.1rem 0.2rem 0.1rem 0.2rem",
    "color": "var(--van-app-text)",
    "font-size": "0.22rem",
    "text-align": "justify"
  }
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.label == '' ? _renderSlot(_ctx.$slots, "label", {
    key: 0
  }, undefined, true) : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.label), 1))])]);
}