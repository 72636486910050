import { onMounted, reactive, toRefs } from 'vue';
import { ayoInfo } from "../../api/restful";
import { useRoute } from "vue-router";
import dsb from "../../lib/dsbridge";
var route;
export default {
  name: "AyoPhoto",
  components: {},
  methods: {},
  setup() {
    route = useRoute();
    const state = reactive({
      list: []
    });
    const getdata = () => {
      ayoInfo.photoList(route.query.uid, route.query.token, route.query.tuid).then(res => {
        if (res.data.code == 0) {
          state.list = res.data.result.list;
          dsb.h5config(res.data.result.h5);
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};