import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, ref } from 'vue';
import { qb, moo, base } from "@/api/restful";
import { useRoute, useRouter } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button, Collapse, CollapseItem } from "vant";
import OrderInfo from '@/components/moo/OrderInfo.vue';
export default {
  name: "MooServiceResult",
  components: {
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [OrderInfo.name]: OrderInfo
  },
  methods: {
    clickButton: function (channel) {
      this.payload.amount = this.service_cost;
      this.payload.channel = channel;
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 5
      });
      base.req(this.api, {
        params: this.payload
      }).then(res => {
        if (res.code == 0) {
          dsb.mgo("scheme", {
            "url": res.res.link
          });
        } else {
          dsb.mgo("setLoading", {
            "act": 0
          });
        }
      });
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      query: {},
      api: "",
      payload: {},
      member: 0,
      service_image: "",
      service_cost: 0,
      service_title: "",
      service_minutes: 0,
      order_gender: 0,
      order_therapistFlag: 0,
      loading: true
    });
    dsb.dsBridge.register('reopen', function () {
      setTimeout(function () {
        dsb.mgo("setLoading", {
          "act": 0
        });
      }, 4000);
    });
    const checkStatus = function () {
      moo.orderStatus(route.query.id).then(res => {
        if (res.data.code == 0) {
          if (res.res.statusStr != "unpaid") {
            dsb.mgo("setLoading", {
              "act": 0
            });
            dsb.mgo("removeCtr", {
              "name": ["serviceInfo", "serviceOrder", "OrderInfo"]
            });
            router.push({
              name: 'MooOrderInfo',
              query: route.query
            });
          } else {
            setTimeout(checkStatus, 2000);
          }
        }
      });
    };

    // 调取api获取数据
    const getdata = () => {
      moo.orderInfo(route.query.id).then(res => {
        if (res.data.code == 0) {
          state.service_image = res.res.service_image;
          state.service_cost = res.res.service_cost;
          state.service_title = res.res.service_title;
          state.service_minutes = res.res.service_minutes;
          state.order_gender = res.res.gender;
          state.order_therapistFlag = res.res.therapistFlag;
          state.loading = false;
          setTimeout(checkStatus, 2000);
        }
      });
      qb.qbCz("订单").then(res => {
        if (res.data.code == 0) {
          state.api = res.res.api;
          state.payload = res.res.payload;
          state.payload.orderId = route.query.id;
          state.payload.shock = 1;
          dsb.h5config({
            "reopen": "reopen"
          });
        }
      });
    };
    onMounted(getdata);
    const activeName = ref('0');
    return {
      ...toRefs(state),
      activeName
    };
  }
};