import { ref } from 'vue';
import { Button, CountDown } from "vant";
//import dsb from "@/lib/dsbridge"
export default {
  name: 'OrderStatus',
  components: {
    [Button.name]: Button,
    [CountDown.name]: CountDown
  },
  props: {
    status: {
      type: String,
      default: () => "timeout"
    },
    mink: {
      type: Object,
      default: () => {}
    },
    des: String,
    icon: String,
    outOrderTime: {
      type: Number,
      default: () => 0
    },
    serviceEndTime: {
      type: Number,
      default: () => 0
    },
    buttonFunc: {
      type: Function,
      default: () => {}
    }
  },
  methods: {},
  setup(props) {
    const cdtime = ref(props.outOrderTime - Date.parse(new Date()));
    const endtime = ref(props.serviceEndTime - Date.parse(new Date()) + 30000);
    return {
      cdtime,
      endtime
    };
  },
  watch: {
    serviceEndTime: function (val) {
      this.endtime = val - Date.parse(new Date()) + 30000;
    }
  }
};