import { onMounted, reactive, toRefs, watch } from 'vue';
import { userdata } from "../../api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { RadioGroup, Radio, CellGroup, Cell } from 'vant';
var route;
export default {
  name: "AppConfig",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  },
  methods: {
    chagneMute: function () {
      dsb.mgo("toast", {
        "word": "设置成功"
      });
      this.isMute = this.isMute ? 0 : 1;
    },
    chagneIm: function () {
      dsb.mgo("toast", {
        "word": "设置成功"
      });
      this.imDetail = this.imDetail ? 0 : 1;
    },
    pushTimeLimitTypeChangeHandler(value) {
      this.pushTimeLimitType = value;
    },
    selectLanguage() {
      dsb.mgo("select", {
        "content": ["中文", "English"]
      }, "", res => {
        var v = JSON.parse(res);
        if (v.index == 0) {
          this.language = 'zh';
        } else {
          this.language = 'en';
        }
      });
    },
    deleteAccount() {
      dsb.mgo("alert", {
        "title": "操作",
        "word": "删除账号后，系统将从数据库清楚所有资料，无法恢复。",
        "bt0": "取消",
        "bt1": "删除"
      }, "", function (res) {
        var v = JSON.parse(res);
        if (v.button == 1) {
          dsb.mgo("alert", {
            "title": "删除成功",
            "word": "系统将会自动登出。",
            "bt1": "确定"
          }, "", function () {
            dsb.mgo("logout", {
              "fast": 1,
              "log": "remove",
              "back": 1
            });
          });
        }
      });
    },
    cacheClear() {
      dsb.mgo("cacheClear", {
        "action": 1
      }, "", function () {
        dsb.mgo("toast", {
          "word": "清除成功"
        });
        this.cacheSize = "大小0.0M";
      });
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      pushTimeLimit: 0,
      isShock: false,
      isSound: false,
      isBadge: false,
      language: "",
      isMute: false,
      imDetail: false,
      timenow: Date.parse(new Date()) / 1000,
      isMember: false,
      pushTimeLimitType: "0",
      cacheSize: "大小0.0M"
    });
    // 调取api获取数据
    const getdata = () => {
      document.body.style.backgroundColor = "#f2f5f7";
      let appconfig = dsb.mgo("sysconfig", {});
      state.isShock = appconfig.res.shock == 1 ? true : false;
      state.isSound = appconfig.res.sound == 1 ? true : false;
      state.isBadge = appconfig.res.badge == 1 ? true : false;
      if (appconfig.res.lanlist.base) {
        delete appconfig.res.lanlist.base;
      }
      if (appconfig.res.lanlist.length < 2) {
        state.language = appconfig.res.applan;
      } else {
        state.language = '';
      }
      watch(() => state.isShock, val => {
        dsb.mgo("sysconfig", {
          "sk": val ? 1 : 0
        });
      });
      watch(() => state.language, val => {
        dsb.mgo("sysconfig", {
          "lan": val
        });
      });
      watch(() => state.isSound, val => {
        dsb.mgo("sysconfig", {
          "sound": val ? 1 : 0
        });
      });
      watch(() => state.isBadge, val => {
        dsb.mgo("sysconfig", {
          "badge": val ? 1 : 0
        });
      });
      userdata.appconfigData(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          state.pushTimeLimit = res.data.result.pushTimeLimit;
          state.isMute = res.data.result.isMute == 0 ? true : false;
          state.imDetail = res.data.result.imDetail == 1 ? true : false;
          state.isMember = res.data.result.isMember == 1 ? true : false;
          if (state.pushTimeLimit > state.timenow + 12 * 60 * 60) {
            state.pushTimeLimitType = "1";
          } else if (state.pushTimeLimit < state.timenow + 12 * 60 * 60 + 1 && state.pushTimeLimit > state.timenow) {
            state.pushTimeLimitType = "2";
          } else if (state.pushTimeLimit == 3600) {
            state.pushTimeLimitType = "3";
          } else {
            state.pushTimeLimitType = "4";
          }
          dsb.h5config(res.data.result.h5);
        }
        dsb.mgo("cacheClear", {
          "action": 0
        }, "", function (v) {
          res = JSON.parse(v);
          state.cacheSize = "大小" + res.size.toFixed(1) + "M";
        });
        watch(() => [state.pushTimeLimitType, state.isMute, state.imDetail, state.isBadge], ([nPushTimeLimitType, nIsMute, nImDetail, nIsBadge], [oPushTimeLimitType, oIsMute, oImDetail, oIsBadge]) => {
          //console.log("新值:", nPushTimeLimitType,nIsMute,nImDetail, "老值:", oPushTimeLimitType,oIsMute,oImDetail);
          //console.log(state.isMember)
          if (nPushTimeLimitType == oPushTimeLimitType && nIsMute == oIsMute && nImDetail == oImDetail && nIsBadge == oIsBadge) {
            return;
          }
          var pushTimeLimitType = 19000000000;
          if (nPushTimeLimitType == "2") {
            pushTimeLimitType = state.timenow + 12 * 60 * 60;
          } else if (nPushTimeLimitType == "3") {
            pushTimeLimitType = 3600;
          } else if (nPushTimeLimitType == "4") {
            pushTimeLimitType = 0;
          }
          userdata.appconfigSet(route.query.uid, route.query.token, pushTimeLimitType, nIsMute ? 1 : 0, nImDetail ? 1 : 0, nIsBadge ? 1 : 0);
          dsb.mgo("toast", {
            "word": "已设置"
          });
        }, {
          deep: true
        });
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};