import { base } from './restful.js';

export default class useContactRestful  {    
    static async get(tuid,uid="",token="") {
        //获取APP设置
        const params = {
            "uid":uid,
            "token":token,
            "tuid":tuid
        }
        return base.req("contact_req_get",{params:params})
    }
}
