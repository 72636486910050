import { onMounted, reactive, toRefs } from 'vue';
import { qb } from "@/api/restful";
import { Button, Field } from "vant";
import dsb from "@/lib/dsbridge";
export default {
  name: "MooQbWithdraw",
  components: {
    [Button.name]: Button,
    [Field.name]: Field
  },
  methods: {
    withdraw: function () {
      if (this.amount == 0) {
        msg = '请输入提现金额';
      }
      var msg = '';
      if (this.withdrawType == '支付宝') {
        if (this.account_alipay == '') {
          msg = '请输入支付宝账号';
        }
      } else {
        if (this.account_bank == '') {
          msg = '请输入银行卡号';
        }
      }
      if (this.account_name == '') {
        msg = '请输入真实姓名';
      }
      if (msg != '') {
        dsb.mgo('alert', {
          'title': '提示',
          'word': msg,
          'bt1': '确定'
        });
        return;
      }
      this.refundLoading = true;
      qb.qbTk(this.withdrawType, this.amount * 100, this.withdrawType == '支付宝' ? this.account_alipay : this.account_bank, this.account_name).then(res => {
        this.refundLoading = false;
        console.log(res);
        if (res.code == 0) {
          this.amount = 0;
          this.qb = res.res.qb;
          dsb.mgo('alert', {
            'style': 'card',
            'title': '提示',
            'word': '申请完成,请留意相关消息。',
            'bt1': '确定',
            'bg': 'dark'
          });
        } else {
          dsb.mgo('alert', {
            'title': '提示',
            'word': res.msg,
            'bt1': '确定'
          });
        }
      });
    }
  },
  setup() {
    const state = reactive({
      refundLoading: false,
      therapist: 1,
      withdrawType: "支付宝",
      amount: 0,
      qb: 0,
      account_alipay: "",
      account_bank: "",
      account_name: ""
    });
    document.body.style.backgroundColor = "#f2f5f7";
    const getdata = () => {
      qb.withdrawInfo().then(res => {
        if (res.code == 0) {
          state.qb = res.res.qb;
          state.withdrawType = res.res.type;
          state.account_name = res.res.name;
          if (state.withdrawType == "支付宝") {
            state.account_alipay = res.res.account;
          } else {
            state.account_bank = res.res.account;
          }
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};