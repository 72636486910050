import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4672f1cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_skeleton = _resolveComponent("van-skeleton");
  return _openBlock(), _createBlock(_component_van_skeleton, {
    title: "",
    row: $props.row,
    loading: _ctx.loading
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      innerHTML: _ctx.rmcontent,
      class: "content"
    }, null, 8, _hoisted_1)]),
    _: 1
  }, 8, ["row", "loading"]);
}