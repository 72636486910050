import { onMounted, reactive, toRefs } from 'vue';
import { member } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button } from "vant";
import PriceBlock from "./PriceBlock.vue";
import CenterPrimeItem from "./CenterPrimeItem.vue";
var route;
export default {
  name: "YogetherMember",
  components: {
    [Button.name]: Button,
    "PriceBlock": PriceBlock,
    "CenterPrimeItem": CenterPrimeItem
  },
  methods: {
    requestIap: function (pn) {
      var pid = "";
      if (0 == pn) {
        pid = this.left.id;
      } else if (1 == pn) {
        pid = this.right.id;
      }
      dsb.mgo("setLoading", {
        "act": 1
      });
      dsb.mgo("pgc", {
        "id": pid,
        "js": "iapCallback"
      });
    },
    clicArticle: function (rmid) {
      var link = "https://www.tongyouapp.com/privacy";
      if (rmid == "member") {
        link = "https://www.tongyouapp.com/member";
      }
      dsb.mgo("browser", {
        "url": link
      });
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      product: 0,
      memberVaildTime: 0,
      avatar: "",
      nickname: "",
      left: {},
      right: {}
    });
    dsb.dsBridge.register('iapCallback', function (x) {
      console.log(x);
    });

    // 调取api获取数据
    const getdata = () => {
      member.memberInfo(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          state.nickname = res.data.result.nickname;
          state.avatar = res.data.result.avatar;
          state.memberVaildTime = res.data.result.memberVaildTime;
          state.left = res.data.result.left;
          state.right = res.data.result.right;
        }
      });
    };
    state.query = route.query;
    state.query.version = route.params.version;
    onMounted(getdata);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    return {
      ...toRefs(state),
      themeVars
    };
  }
};