import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue';
import { qb } from "@/api/restful";
import { Button } from "vant";
import gsap from 'gsap';
import dsb from "@/lib/dsbridge";
export default {
  name: "MooQbInfo",
  components: {
    [Button.name]: Button
  },
  methods: {
    refund: function () {
      if (this.amount == 0) {
        return;
      }
      this.refundLoading = true;
      qb.qbTk("退款", this.amount * 100).then(res => {
        if (res.code == 0) {
          this.amount = parseInt(res.res.qb / 100);
          this.refundLoading = false;
          dsb.mgo('alert', {
            'style': 'card',
            'title': '提示',
            'word': '申请完成,请留意相关充值渠道',
            'bt1': '确定',
            'bg': 'dark'
          });
        }
      });
    },
    gotoWithdraw: function () {
      if (this.therapist == 0) {
        return;
      }
      dsb.h5({
        "x_titlemode": 1
      }, this.version, "YoSpa/Qb/Withdraw", {
        "uid": this.uid,
        "token": this.token
      });
    }
  },
  setup() {
    const datab = getCurrentInstance();
    const state = reactive({
      refundLoading: false,
      therapist: 0
    });
    const getdata = () => {
      qb.qbInfo().then(res => {
        if (res.code == 0) {
          datab.data.amount = parseInt(res.res.qb / 100);
          state.therapist = res.res.therapist;
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  },
  data() {
    return {
      amount: 0,
      showNumber: 0
    };
  },
  watch: {
    amount(newValue) {
      gsap.to(this, {
        duration: 1,
        showNumber: newValue
      });
    }
  }
};