import { onMounted, reactive, toRefs } from 'vue';
import { moo, captcha } from "@/api/restful";
//import { useRoute } from "vue-router"
import dsb from "@/lib/dsbridge";
import { RadioGroup, Radio, CellGroup, Cell } from 'vant';
export default {
  name: "MooTherapistConfig",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  },
  methods: {
    async setServiceConfig(id) {
      var dict = {};
      dict[id] = this.serviceSwitch[id] ? 1 : 0;
      await moo.therapistConfigSet(dict, null);
    },
    async getCode() {
      if (this.newPhone.length != 11) {
        dsb.mgo("alert", {
          "title": "提示",
          "word": "手机号码长度应为11位",
          "bt1": "确定"
        });
        return;
      }
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 10
      });
      captcha.codeSend("verify", "phone", this.newPhone).then(res => {
        dsb.mgo("setLoading", {
          "act": 0
        });
        if (res.data.code == 0) {
          this.resendTime = 60000;
          setTimeout(() => {
            this.resendTime = 0;
          }, 60000);
        }
      });
    },
    async setServiceConfigPhone() {
      if (this.newPhone == this.oldPhone) {
        dsb.mgo("alert", {
          "title": "提示",
          "word": "设置成功",
          "bt1": "确定"
        });
        return;
      }
      if (this.verifyCode.length != 4) {
        dsb.mgo("alert", {
          "title": "提示",
          "word": "请输入手机号收到4位验证码",
          "bt1": "确定"
        });
        return;
      }
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 10
      });
      await moo.therapistConfigSetPhone(this.newPhone, this.verifyCode).then(res => {
        dsb.mgo("setLoading", {
          "act": 0
        });
        if (res.code == 0) {
          dsb.mgo("alert", {
            "title": "提示",
            "word": "设置成功",
            "bt1": "确定"
          });
          this.oldPhone = this.newPhone;
        } else {
          dsb.mgo("alert", {
            "title": "提示",
            "word": res.msg,
            "bt1": "确定"
          });
        }
      });
    }
  },
  setup() {
    //var route = useRoute(); 
    const state = reactive({
      serviceList: [],
      serviceSwitch: {},
      oldPhone: "",
      newPhone: "",
      verifyCode: "",
      config: {},
      resendTime: 0,
      formatterPhone: value => value = value.replace(/\D/g, '').slice(0, 11),
      formatterCode: value => value = value.replace(/\D/g, '').slice(0, 4)
    });
    // 调取api获取数据
    const getdata = () => {
      document.body.style.backgroundColor = "#f2f5f7";
      moo.therapistConfigInfo().then(res => {
        if (res.data.code == 0) {
          state.serviceList = res.res.serviceList;
          for (var i = 0; i < state.serviceList.length; i++) {
            state.serviceSwitch[state.serviceList[i]] = false;
          }
          state.config = res.res.config;
          for (i = 0; i < state.config.serviceId_list.length; i++) {
            state.serviceSwitch[state.config.serviceId_list[i]] = true;
          }
          state.oldPhone = state.config.phone;
          state.newPhone = state.config.phone;
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};