import "core-js/modules/es.array.push.js";
import dsb from "@/lib/dsbridge";
import { Switch } from 'vant';
export default {
  name: 'InputCell',
  components: {
    [Switch.name]: Switch
  },
  props: {
    iv: {
      type: null,
      default: () => ""
    },
    dv: {
      type: String,
      default: () => ""
    },
    title: {
      type: String,
      default: () => ""
    },
    inputTitle: {
      type: String,
      default: () => "请输入内容"
    },
    mul: {
      type: String,
      default: () => "0"
    },
    placeholder: {
      type: String,
      default: () => ""
    },
    limit: {
      type: String,
      default: () => "0"
    },
    least: {
      type: String,
      default: () => "0"
    },
    select: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => "input"
    },
    inputFunc: {
      type: Function,
      default: null
    },
    label: {
      type: String,
      default: () => ""
    }
  },
  methods: {
    doInput() {
      if (this.inputFunc != null) {
        this.inputFunc();
        return;
      }
      var instance = this;
      if (this.type == "stature") {
        var content = [];
        for (var i = 140; i < 190; i++) {
          content.push(i + "cm");
        }
        dsb.mgo("select", {
          "content": content,
          "default": instance.words
        }, "", function (v) {
          var res = JSON.parse(v);
          instance.words = res.str;
        });
      } else if (this.type == "select") {
        dsb.mgo(this.type, {
          "content": this.select
        }, "", function (v) {
          var res = JSON.parse(v);
          instance.words = res.str;
        });
      } else if (this.type == "dob") {
        var d = new Date();
        d.setFullYear(d.getFullYear() - 18);
        var def = parseInt(d.getTime() / 1000);
        if (this.words.split("-").length == 2) {
          def = new Date(this.words.split("-")[0], this.words.split("-")[1]).getTime() - 1;
          def = parseInt(def / 1000);
        }
        dsb.mgo("date_picker", {
          "type": "YearAndMonth",
          "default": def,
          "limitTime": parseInt(d.getTime() / 1000)
        }, "", function (v) {
          var res = JSON.parse(v);
          instance.words = res.str;
        });
      } else if (-1 == ["switch", "gender"].indexOf(this.type)) {
        dsb.mgo("input", {
          "title": this.inputTitle,
          "words": this.words,
          "mul": this.mul,
          "placeholder": this.placeholder,
          "limit": this.limit,
          "least": this.least
        }, "", function (v) {
          var res = JSON.parse(v);
          instance.words = res.words;
        });
      }
    },
    clickGender(gender) {
      this.words = gender;
    }
  },
  data: function () {
    return {
      words: this.iv
    };
  },
  watch: {
    iv(nv) {
      this.words = nv;
    },
    words(nv) {
      this.$emit('update:iv', nv);
    }
  }
};