export default {
  name: 'MemberPrivilege',
  components: {},
  props: {
    title: String,
    des: String,
    icon: String
  },
  methods: {},
  watch: {}
};