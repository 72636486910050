import { onMounted, reactive, toRefs } from 'vue';
import { useRoute } from "vue-router";
import { ayoInfo } from "@/api/restful";
import useAyoYouthyoRestful from '@/api/ayo-youthyo';
import useContactRestful from '@/api/contact';
import dsb from "@/lib/dsbridge";
import { Button, CellGroup, Cell, Checkbox, CheckboxGroup } from "vant";
import HeadBlur from "@/components/userpage/HeadBlur.vue";
import GalleryBlock from "@/components/userpage/GalleryBlock.vue";
import InfoBlock from "@/components/userpage/InfoBlock.vue";
import EventBlock from "@/components/userpage/EventBlock.vue";
var route;
export default {
  name: "AyoPage",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    "HeadBlur-Content": HeadBlur,
    "GalleryBlock-Content": GalleryBlock,
    "InfoBlock-Content": InfoBlock,
    "EventBlock-Content": EventBlock
  },
  methods: {
    clickPhoto() {
      typeof this.photoMink == "object" ? dsb.mink(this.photoMink) : this.memberNeed(this.photoMink);
    },
    memberNeed(word) {
      dsb.mgo("alert", {
        "title": "提示",
        "word": word,
        "bt0": "取消",
        "bt1": "试用"
      }, "", v => {
        var res = JSON.parse(v);
        if (res.button == 1) {
          dsb.mink(this.memberMink);
        }
      });
    },
    clickContact() {
      if (this.contact.label == undefined) {
        return;
      }
      if (this.contact.label.includes("*")) {
        dsb.mgo("alert", {
          "title": "提示",
          "word": `是否愿意送出‘微愿’(${this.contact.cost / 10}友币)换取联系方式?`,
          "bt0": "取消",
          "bt1": "确定"
        }, "", v => {
          var res = JSON.parse(v);
          if (res.button == 1) {
            useContactRestful.get(this.tuid).then(res => {
              if (res.res.status == 2) {
                dsb.h5({
                  "x_titlemode": 1
                }, this.version, "YouthYo/Zs", {
                  "uid": this.uid,
                  "token": this.token
                });
              } else {
                if (res.res.label.length != 0) {
                  dsb.mgo("toast", {
                    "word": "获取成功"
                  });
                  this.contact.label = res.res.label;
                }
              }
            });
          }
        });
      } else {
        dsb.mgo("clipboard", {
          "word": this.contact.label
        });
        dsb.mgo("toast", {
          "word": "复制成功"
        });
      }
    }
  },
  mounted() {},
  setup() {
    route = useRoute();
    const state = reactive({
      avatar: "",
      nickname: "",
      tuid: "",
      words: "",
      photo_list: [],
      photo_des: "",
      tag_list: [],
      info_list: [],
      event_list: [],
      imMink: {},
      photoMink: {},
      memberMink: {},
      contact: {},
      checked: false,
      stature: "",
      liveness: 0,
      notice: 0,
      lbs: 0
    });
    // 调取api获取数据
    const getdata = () => {
      state.tuid = route.query.tuid;
      useAyoYouthyoRestful.page(route.query.tuid).then(res => {
        if (res.data.code == 0) {
          state.avatar = res.data.result.avatar;
          state.nickname = res.data.result.nickname;
          state.words = res.data.result.words;
          state.tag_list = res.data.result.tag_list;
          state.photo_list = res.data.result.photo_list;
          state.photo_des = res.data.result.photo_des;
          state.info_list = res.data.result.info_list;
          state.event_list = res.data.result.event_list;
          state.imMink = res.data.result.imMink;
          state.photoMink = res.data.result.photoMink;
          state.memberMink = res.data.result.memberMink;
          state.contact = res.data.result.contact;
          dsb.h5config(res.data.result.h5);
          dsb.dsBridge.register('toolbarClick0', function () {
            useAyoYouthyoRestful.chat(route.query.tuid).then(res => {
              typeof res.res.mink == "object" ? dsb.mink(res.res.mink) : this.memberNeed(res.res.mink);
            });
          });
          dsb.dsBridge.register('toolbarClick_like', function () {
            ayoInfo.like(route.query.uid, route.query.token, route.query.tuid, '1').then(res => {
              dsb.h5config(res.data.result.h5);
              dsb.mgo("toast", {
                "word": "关注成功",
                "duration": "1000"
              });
            });
            return "";
          });
          dsb.dsBridge.register('toolbarClick_unlike', function () {
            ayoInfo.like(route.query.uid, route.query.token, route.query.tuid, '0').then(res => {
              dsb.h5config(res.data.result.h5);
              dsb.mgo("toast", {
                "word": "取消关注",
                "duration": "1000"
              });
            });
            return "";
          });
          dsb.dsBridge.register('option', function () {
            dsb.mgo("select", {
              "content": ["拉黑用户", "举报用户(色情、欺诈等)"],
              "title": "请选择操作"
            }, "", function (v) {
              var res = JSON.parse(v);
              if (res.index == 0) {
                dsb.mgo("alert", {
                  "title": "操作",
                  "word": "确定拉黑该用户吗？",
                  "bt0": "取消",
                  "bt1": "确定"
                }, "", function (v) {
                  if (JSON.parse(v).button == 1) {
                    dsb.mgo("blacklist", {
                      "uid": route.query.tuid,
                      "set": 1
                    }, "", function () {
                      dsb.mgo("toast", {
                        "word": "拉黑成功",
                        "duration": "2000"
                      });
                      dsb.mgo("back");
                    });
                  }
                });
              } else if (res.index == 1) {
                dsb.mgo("alert", {
                  "title": "操作",
                  "word": "确定举报该用户吗？",
                  "bt0": "取消",
                  "bt1": "确定"
                }, "", function (v) {
                  if (JSON.parse(v).button == 1) {
                    dsb.mgo("report", {
                      "uid": route.query.tuid,
                      "reason": 1
                    }, "", function () {
                      dsb.mgo("toast", {
                        "word": "举报成功",
                        "duration": "2000"
                      });
                    });
                  }
                });
              }
            });
            return "";
          });
        }
      });
      let body = document.querySelector("body");
      body.style.overflow = "hidden";
      body.style.backgroundColor = "rgba(0,0,0,0)";
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};