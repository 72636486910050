import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs } from 'vue';
import { showToast } from 'vant';
export default {
  name: "MooYoSpaIndex",
  components: {
    [showToast.name]: showToast
  },
  methods: {
    clickButton: function () {
      alert("APP正在上架中");
    },
    clickPrivacy: function (v) {
      if (v == 0) {
        this.$router.push({
          path: '/YoSpa/privacy'
        });
      } else {
        this.$router.push({
          path: '/YoSpa/member'
        });
      }
    }
  },
  setup() {
    const state = reactive({
      str: ""
    });
    const getdata = () => {};
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};