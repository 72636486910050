//index.js


import { createRouter, createWebHistory} from "vue-router";
import UserBlacklist from '@/pages/UserBlacklist/UserBlacklist.vue'
import HelpCenter from '@/pages/HelpCenter/HelpCenter.vue'
import AppIntro from '@/pages/AppIntro/AppIntro.vue'
import AppConfig from '@/pages/AppConfig/AppConfig.vue'
import RmArticle from '@/pages/RmArticle/RmArticle.vue'
import ProfileModify from '@/pages/ProfileModify/ProfileModify.vue'
import PhotoManager from '@/pages/PhotoManager/PhotoManager.vue'
import AyoApply from '@/pages/AyoApply/AyoApply.vue'
import AyoPage from '@/pages/AyoPage/AyoPage.vue'
import AyoPhoto from '@/pages/AyoPhoto/AyoPhoto.vue'
import YoCreate from '@/pages/Yo/YoCreate.vue'
import YoInfo from '@/pages/Yo/YoInfo.vue'
import MgoCheck from '@/pages/MgoCheck/MgoCheck.vue'
import MemberCenter from '@/pages/Member/MemberCenter.vue'

import OtherPrivacy from '@/pages/Other/OtherPrivacy.vue'
import OtherMember from '@/pages/Other/OtherMember.vue'
import OtherContact from '@/pages/Other/OtherContact.vue'
import OtherAgreementTopup from '@/pages/Other/OtherAgreementTopup.vue'


import YogetherMember from '@/pages/Yogether/member/YogetherMember.vue'


//stick
import YouthYoQbZs from '@/pages/YouthYo/Qb/Zs.vue'
import YouthYoQbZd from '@/pages/YouthYo/Qb/Zd.vue'




var routes = [{
	path: "/privacy",
	name: "OtherPrivacy",
    meta: { title: "隐私政策" },
	component: OtherPrivacy
},{
	path: "/member",
	name: "OtherMember",
    meta: { title: "会员政策" },
	component: OtherMember
},{
	path: "/contact",
	name: "OtherContact",
    meta: { title: "联系我们" },
	component: OtherContact
},{
	path: "/agreement/topup",
	name: "OtherAgreementTopup",
    meta: { title: "充值协议" },
	component: OtherAgreementTopup
},


{
	path: "/:version/UserBlacklist",
	name: "UserBlacklist",
    meta: { title: "黑名单" },
	component: UserBlacklist
},{
	path: "/:version/HelpCenter",
	name: "HelpCenter",
    meta: { title: "帮助说明" },
	component: HelpCenter
},{
	path: "/:version/AppIntro",
	name: "AppIntro",
    meta: { title: "关于" },
	component: AppIntro
},{
	path: "/:version/AppConfig",
	name: "AppConfig",
    meta: { title: "APP设置" },
	component: AppConfig
},{
	path: "/:version/RmArticle",
	name: "RmArticle",
    meta: { title: "文章" },
	component: RmArticle
},{
	path: "/:version/ProfileModify",
	name: "ProfileModify",
    meta: { title: "用户资料" },
	component: ProfileModify
},{
	path: "/:version/PhotoManager",
	name: "PhotoManager",
    meta: { title: "生活照管理" },
	component: PhotoManager
},{
	path: "/:version/AyoPage",
	name: "AyoPage",
    meta: { title: "用户主页" },
	component: AyoPage
},{
	path: "/:version/AyoPhoto",
	name: "AyoPhoto",
    meta: { title: "生活照" },
	component: AyoPhoto
},{
	path: "/:version/YoCreate",
	name: "YoCreate",
    meta: { title: "活动申请" },
	component: YoCreate
},{
	path: "/:version/YoInfo",
	name: "YoInfo",
    meta: { title: "活动详情" },
	component: YoInfo
},{
	path: "/:version/MgoCheck",
	name: "MgoCheck",
    meta: { title: "MGO页面" },
	component: MgoCheck
},{
	path: "/:version/MemberCenter",
	name: "MemberCenter",
    meta: { title: "会员信息" },
	component: MemberCenter
},{
	path: "/:version/YogetherMember",
	name: "YogetherMember",
    meta: { title: "会员信息" },
	component: YogetherMember
},
//youthyo
{
	path: "/:version/YouthYo/Zs",
	name: "YouthYoQbZs",
    meta: { title: "钱包" },
	component: YouthYoQbZs
},
{
	path: "/:version/YouthYo/Ayo/Apply",
	name: "AyoApply",
    meta: { title: "资料申请" },
	component: AyoApply
},
{
	path: "/:version/YouthYoQbZd",
	name: "YouthYoQbZd",
    meta: { title: "账单" },
	component: YouthYoQbZd
},

];

//moo
import MooServiceInfo from '@/pages/moo/Service/Info.vue'
import MooServiceResult from '@/pages/moo/Service/Result.vue'
import MooServiceOrder from '@/pages/moo/Service/Order.vue'
import MooOrderCancel from '@/pages/moo/Order/Cancel.vue'
import MooOrderInfo from '@/pages/moo/Order/Info.vue'
import MooMemberMain from '@/pages/moo/Member/Main.vue'
import MooTherapistPrior from '@/pages/moo/Therapist/Prior.vue'
import MooTherapistApply from '@/pages/moo/Therapist/Apply.vue'
import MooTherapistConfig from '@/pages/moo/Therapist/Config.vue'
import MooTherapistPage from '@/pages/moo/Therapist/Page.vue'
import MooQbInfo from '@/pages/moo/Qb/Info.vue'
import MooQbWithdraw from '@/pages/moo/Qb/Withdraw.vue'
import MooYoSpaPrivacy from '@/pages/moo/YoSpa/privacy.vue'
import MooYoSpaMember from '@/pages/moo/YoSpa/member.vue'
import MooYoSpaIndex from '@/pages/moo/YoSpa/index.vue'
routes.push(
{
	path: "/YoSpa/privacy",
	name: "MooYoSpaPrivacy",
	meta: { title: "隐私政策" },
	component: MooYoSpaPrivacy
},{
	path: "/YoSpa/member",
	name: "MooYoSpaMember",
	meta: { title: "会员政策" },
	component: MooYoSpaMember
},{
	path: "/YoSpa",
	name: "MooYoSpaIndex",
	meta: { title: "上门按摩YoSpa" },
	component: MooYoSpaIndex
},{
	path: "/:version/YoSpa/Service/Info",
	name: "MooServiceInfo",
    meta: { title: "服务详细" },
	component: MooServiceInfo
},{
	path: "/:version/YoSpa/Service/Order",
	name: "MooServiceOrder",
    meta: { title: "订单预约" },
	component: MooServiceOrder
},{
	path: "/:version/YoSpa/Service/Result",
	name: "MooServiceResult",
    meta: { title: "订单结果" },
	component: MooServiceResult
},{
	path: "/:version/YoSpa/Order/Cancel",
	name: "MooOrderCancel",
    meta: { title: "取消订单" },
	component: MooOrderCancel
},{
	path: "/:version/YoSpa/Order/Info",
	name: "MooOrderInfo",
    meta: { title: "订单详细" },
	component: MooOrderInfo
},{
	path: "/:version/YoSpa/Member/Main",
	name: "MooMemberMain",
    meta: { title: "会员中心" },
	component: MooMemberMain
},{
	path: "/:version/YoSpa/Therapist/Prior",
	name: "MooTherapistPrior",
    meta: { title: "优先技师" },
	component: MooTherapistPrior
},{
	path: "/:version/YoSpa/Therapist/Config",
	name: "MooTherapistConfig",
    meta: { title: "技师设置" },
	component: MooTherapistConfig
},{
	path: "/:version/YoSpa/Therapist/Page",
	name: "MooTherapistPage",
    meta: { title: "技师主页" },
	component: MooTherapistPage
},{
	path: "/:version/YoSpa/Therapist/Apply",
	name: "MooTherapistApply",
    meta: { title: "平台进驻" },
	component: MooTherapistApply
},{
	path: "/:version/YoSpa/Qb/Info",
	name: "MooQbInfo",
    meta: { title: "钱包" },
	component: MooQbInfo
},{
	path: "/:version/YoSpa/Qb/Withdraw",
	name: "MooQbWithdraw",
    meta: { title: "提现" },
	component: MooQbWithdraw
})





const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
