export default {
  name: "CenterPrimeItem",
  props: {
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "无限次聊天"
    },
    des: {
      type: String,
      default: "图片每天限制，不过每次机会"
    }
  },
  components: {},
  methods: {},
  data: function () {
    return {};
  },
  watch: {}
};