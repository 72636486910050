import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs } from 'vue';
import { yo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { formatDateTime } from "@/lib/tool";
import InputCell from "@/components/InputCell.vue";
import UploadCross from "@/components/photo/UploadCross.vue";
import { getBRDatePickerData } from "@/lib/tool";
var route;
export default {
  name: "YoCreate",
  components: {
    'input-cell': InputCell,
    'upload-cross': UploadCross
  },
  methods: {
    pickType: function () {
      var instance = this;
      dsb.mgo("pickType", {
        "data": this.pickType_list
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.eventType = res.name;
      });
    },
    pickPos: function () {
      var instance = this;
      var res = dsb.mgo("lbs_info", {});
      if (res) {
        if (res.status == 1) {
          if (res.res.lbs == 0) {
            dsb.mgo("lbs_request", {
              "title": "提示",
              "word": "需要在设置中打开位置",
              "bt0": "取消",
              "bt1": "设置"
            }, "", function (v) {
              console.log(v);
            });
          } else {
            dsb.mgo("pickAdr", {
              "lat": res.res.lat,
              "lon": res.res.lon
            }, "", function (v) {
              var res = JSON.parse(v);
              instance.posStr = res.name;
              instance.posDict = res;
            });
          }
        }
      }
    },
    pickDate: function () {
      var instance = this;
      var list = getBRDatePickerData(21, 60 * 60);
      dsb.mgo("picker", {
        "list": list,
        "row": 3,
        "default": [0, 0, 0]
      }, "", function (v) {
        var res = JSON.parse(v);
        var date = new Date(res[2].key.replaceAll("-", "/"));
        instance.eventDate = parseInt(date.getTime() / 1000);
        instance.eventDateStr = res[0].value + "," + date.getHours() + "点" + date.getMinutes() + "分";
      });
    },
    goback() {
      dsb.mgo("back", {
        "ts": "1"
      });
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      photo_list: [],
      pickType_list: {},
      eventType: "",
      words: "",
      posStr: "",
      posDict: null,
      gender: "3",
      eventDate: 0,
      eventDateStr: "",
      duration: "2小时",
      anonymous: false,
      contact: true,
      photo: [],
      query: {},
      waitforreview: false
    });
    const waitpage = t => {
      state.waitforreview = t;
      dsb.mgo("setBarVisible", {
        "visible": t ? 0 : 1,
        'pos': 3
      });
    };
    // 调取api获取数据
    const getdata = () => {
      yo.stickCreatePage(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          state.pickType_list = res.data.result.pickType;
          state.eventType = res.data.result.type;
          state.words = res.data.result.words;
          if (res.data.result.poi.name) {
            state.posDict = res.data.result.poi;
            state.posStr = res.data.result.poi.name;
          }
          if (res.data.result.events_time != 0) {
            state.eventDate = res.data.result.events_time;
            state.eventDateStr = formatDateTime(res.data.result.events_time * 1000);
          }
          switch (res.data.result.events_duration) {
            case 1:
              state.duration = "1小时";
              break;
            case 0:
            case 2:
              state.duration = "2小时";
              break;
            case 3:
              state.duration = "3小时";
              break;
            default:
              state.duration = "4小时或更多";
          }
          state.gender = res.data.result.gender_flag.toString();
          state.anonymous = res.data.result.public == 0 ? true : false;
          for (var i in res.data.result.photo) {
            state.photo.push({
              "url": res.data.result.photo[i]
            });
          }
          dsb.h5config(res.data.result.h5);
          if (res.data.result.status == 0) {
            waitpage(true);
          }
          dsb.dsBridge.register('toolbarClick', function () {
            var errmsg = "";
            if (state.eventType == "") {
              errmsg = "请选择类型";
            }
            if (state.words == "") {
              errmsg = "请填写邀请内容";
            }
            if (state.eventDate == 0) {
              errmsg = "请选择邀请时间";
            }
            if (state.posDict == null) {
              errmsg = "请选择位置";
            }
            if (state.photo.length == 0) {
              errmsg = "请上传照片";
            }
            if (errmsg != "") {
              dsb.mgo("alert", {
                "word": errmsg,
                "shock": 1
              });
              return;
            }
            var apidata = {
              "type": state.eventType,
              "words": state.words,
              "gender_flag": state.gender,
              "events_time": state.eventDate,
              "gather_time": 0,
              "public": state.anonymous ? 0 : 1,
              "contact": state.contact ? 1 : 0,
              "photo": [],
              "poi": state.posDict
            };
            switch (state.eventDateStr) {
              case "1小时":
                apidata["events_duration"] = 1;
                break;
              case "2小时":
                apidata["events_duration"] = 2;
                break;
              case "3小时":
                apidata["events_duration"] = 3;
                break;
              default:
                apidata["events_duration"] = 4;
            }
            for (var i in state.photo) {
              apidata["photo"].push(state.photo[i].url);
            }
            yo.stickApply(route.query.uid, route.query.token, apidata).then(res => {
              if (res.data.code == 0) {
                if (res.data.result.errcode == 0) {
                  waitpage(true);
                  dsb.mgo("mustRefresh", {
                    "index": 1
                  });
                }
              }
            });
            return "";
          });
        }
      });
    };
    state.query = route.query;
    state.query.version = route.params.version;
    onMounted(getdata);
    return {
      ...toRefs(state),
      waitpage
    };
  }
};