export default{
    getUrlKey: function (name,def=null) {
        //return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
        let url = location.href
        let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;   
        let result = {};
        url.replace(pattern, ($, $1, $2)=>{
            result[$1] = $2;
        })
        return result[name] || def;
    }
}