import { onMounted, reactive, toRefs } from 'vue';
export default {
  name: "OtherAgreementTopup",
  components: {},
  methods: {},
  setup() {
    const state = reactive({});
    const getdata = () => {};
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};