import { base } from './restful.js';

export default class useAyoYouthyoRestful  {    
    static async page(tuid,uid="",token="") {
        //获取APP设置
        const params = {
            "uid":uid,
            "token":token,
            "tuid":tuid
        }
        return base.req("ayo_h5_page_youthyo",{params:params})
    }

    static async chat(tuid,uid="",token="") {
        //获取APP设置
        const params = {
            "uid":uid,
            "token":token,
            "tuid":tuid
        }
        return base.req("ayo_req_chat_youthyo",{params:params})
    }

    static async apply(params,uid="",token="") {
        //获取APP设置
        params["uid"] = uid
        params["token"] = token
        return base.req("ayo_req_apply_youthyo",{params:params})
    }


    
}
