import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, watch } from 'vue';
import { photoData } from "@/api/restful";
import { useRoute } from "vue-router";
import { CellGroup, Cell } from "vant";
import dsb from "@/lib/dsbridge";
import InfoBlock from "@/components/common/block/info.vue";
import UploadCross from "@/components/photo/UploadCross.vue";
var route;
export default {
  name: "PhotoManager",
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [InfoBlock.name]: InfoBlock,
    'upload-cross': UploadCross
  },
  methods: {},
  setup() {
    route = useRoute();
    const state = reactive({
      list: [],
      showNotify: false
    });
    // 调取api获取数据
    const getdata = () => {
      photoData.photoManager(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          for (var i in res.data.result.list) {
            state.list.push(res.data.result.list[i]);
          }
          dsb.h5config(res.data.result.h5);
        }
      });
      watch(() => state.list, list => {
        var showlist = [];
        list.forEach(v => {
          if (v.status in [0, 1]) {
            showlist.push(v);
          }
        });
        dsb.mgo("message", {
          "num": 1,
          "js": "updatePhoto",
          "data": showlist
        }, "", function () {});
      }, {
        deep: true
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};