import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, item => {
    return _openBlock(), _createElementBlock("li", {
      class: "image",
      key: item.t
    }, [_createVNode(_component_van_image, {
      src: item.i,
      "lazy-load": ""
    }, null, 8, ["src"])]);
  }), 128))]);
}