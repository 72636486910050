import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "color": "var(--van-app-text-des)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createBlock(_component_van_cell, {
    title: $props.title
  }, {
    label: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($props.date), 1)]),
    value: _withCtx(() => [_createElementVNode("span", {
      style: _normalizeStyle('color:' + ($props.change >= 0 ? 'var(--van-app-male)' : 'var(--van-app-female)') + ';')
    }, _toDisplayString($props.change > 0 ? "+" + $props.change : $props.change) + " 友币", 5)]),
    _: 1
  }, 8, ["title"]);
}