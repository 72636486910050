export default {
  name: 'OrderInfo',
  components: {},
  props: {
    title: {
      type: String
    },
    duration: {
      type: String
    },
    cost: {
      type: String
    },
    gender: {
      type: Number
    },
    therapistGenderFlag: {
      type: Number
    },
    image: {
      type: String
    }
  },
  methods: {},
  watch: {}
};