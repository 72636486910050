import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  style: {
    "width": "80%"
  }
};
const _hoisted_2 = {
  style: {
    "color": "var(--van-app-text-des)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_switch = _resolveComponent("van-switch");
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createBlock(_component_van_cell, {
    title: $props.title,
    "is-link": "",
    onClick: _cache[4] || (_cache[4] = $event => $options.doInput()),
    center: ""
  }, _createSlots({
    _: 2
  }, [$props.type == 'switch' ? {
    name: "right-icon",
    fn: _withCtx(() => [_createVNode(_component_van_switch, {
      modelValue: _ctx.words,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.words = $event),
      size: "24"
    }, null, 8, ["modelValue"])]),
    key: "0"
  } : $props.type == 'gender' ? {
    name: "right-icon",
    fn: _withCtx(() => [_createVNode(_component_van_button, {
      type: "primary",
      size: "small",
      style: {
        "border-radius": "0.2rem",
        "margin-right": "0.15rem",
        "width": "0.9rem"
      },
      color: _ctx.words != '3' ? 'var(--van-text-color-2)' : 'var(--van-primary-color)',
      plain: _ctx.words != '3',
      onClick: _cache[1] || (_cache[1] = $event => $options.clickGender('3'))
    }, {
      default: _withCtx(() => [_createTextVNode("不限")]),
      _: 1
    }, 8, ["color", "plain"]), _createVNode(_component_van_button, {
      type: "primary",
      size: "small",
      style: {
        "border-radius": "0.2rem",
        "margin-right": "0.15rem",
        "width": "0.9rem"
      },
      color: _ctx.words != '1' ? 'var(--van-text-color-2)' : 'var(--van-primary-color)',
      plain: _ctx.words != '1',
      onClick: _cache[2] || (_cache[2] = $event => $options.clickGender('1'))
    }, {
      default: _withCtx(() => [_createTextVNode("男")]),
      _: 1
    }, 8, ["color", "plain"]), _createVNode(_component_van_button, {
      type: "primary",
      size: "small",
      style: {
        "border-radius": "0.2rem",
        "margin-right": "0.15rem",
        "width": "0.9rem"
      },
      color: _ctx.words != '2' ? 'var(--van-text-color-2)' : 'var(--van-primary-color)',
      plain: _ctx.words != '2',
      onClick: _cache[3] || (_cache[3] = $event => $options.clickGender('2'))
    }, {
      default: _withCtx(() => [_createTextVNode("女")]),
      _: 1
    }, 8, ["color", "plain"])]),
    key: "1"
  } : {
    name: "value",
    fn: _withCtx(() => [_ctx.words == '' ? (_openBlock(), _createElementBlock("span", {
      key: 0,
      style: {
        "color": "var(--van-warning-color)"
      }
    }, _toDisplayString($props.dv), 1)) : (_openBlock(), _createElementBlock("span", {
      key: 1,
      style: {
        "color": "var(--van-primary-color)"
      }
    }, _toDisplayString(_ctx.words), 1))]),
    key: "2"
  }, $props.label != '' ? {
    name: "label",
    fn: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.label), 1)])]),
    key: "3"
  } : undefined]), 1032, ["title"]);
}