export default {
  name: 'ZdListCell',
  props: {
    title: {
      type: String,
      default: () => ""
    },
    change: {
      type: Number,
      default: () => 0
    },
    date: {
      type: String,
      default: () => ""
    }
  },
  methods: {},
  watch: {},
  data: function () {
    return {
      ch: this.change
    };
  }
};