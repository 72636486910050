import { onMounted, reactive, toRefs } from 'vue';
import { qb } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import ZdListCell from "./ZdListCell.vue";
var route;
export default {
  name: "YouthYoQbZd",
  components: {
    'cell': ZdListCell
  },
  methods: {
    clickOption: function (e) {
      for (let i in this.opList) {
        this.$refs.jg[i].pick = i == e;
      }
    },
    clickAgreement: function () {
      dsb.mgo(this.agreementMink.e, this.agreementMink);
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      list: []
    });

    // 调取api获取数据
    const getdata = () => {
      qb.qbZd().then(res => {
        if (res.data.code == 0) {
          state.list = res.data.result.list;
          if (state.list.length == 0) {
            state.list = [{
              label: '暂无数据',
              change: 0,
              date: '当前'
            }];
          }
        }
      });
    };
    onMounted(getdata);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    return {
      ...toRefs(state),
      themeVars
    };
  }
};