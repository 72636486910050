import dsb from "@/lib/dsbridge";
export default {
  name: 'PhotoCell',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    uid: {
      type: String,
      default: () => ""
    },
    token: {
      type: String,
      default: () => ""
    },
    version: {
      type: String,
      default: () => ""
    }
  },
  methods: {
    photoManager() {
      dsb.h5({}, this.version, "PhotoManager", {
        "uid": this.uid,
        "token": this.token
      });
    }
  }
};