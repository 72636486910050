var dsBridge = require('dsbridge')
export default {
    dsBridge,
    callmethod (name, data, callback) {
        callback(dsBridge.call(name, data, callback))
    },
    registermethod (tag, callback) {
        dsBridge.register(tag, callback)
    },
    mgo(name,data={},link="",callback=null){
        if(null===data.shock){
            data.shock = "1"
        }else{
            if(parseInt(data.shock)==0){
                delete data.shock
            }
        }
        if(link=="" && data.link){
            link = data.link 
        }
        var params = {
            "a":name,
            "p":data,
            "l":link
        }
        
        if(callback==null){
            return dsBridge.call("h5mgo", params)
        }else{
            return dsBridge.call("h5mgoAsyn", params,callback)
        }
        
    },
    mink(info,callback=null){
        var name = info.e
        var data = info
        if(!data.shock){
            data.shock = "1"
        }
        var link = ""
        if(data.link){
            link = data.link 
        }

        var params = {
            "a":name,
            "p":data,
            "l":link
        }
        if(callback==null){
            return dsBridge.call("h5mgo", params)
        }else{
            return dsBridge.call("h5mgoAsyn", params,callback)
        }
    },
    h5(data,version,page,query){
        const qs=require('qs')
        var link = window.location.protocol + "//"+window.location.host+"/"+version+"/"+page;
        if(query!={}){
            link += "?"+ qs.stringify(query); 
        }
        return this.mgo("h5api",data,link)
    },
    h5config(data){
        return dsBridge.call("h5config", data)
    },
    appinfo(){
        return this.mgo("appinfo",{})
    }

}

