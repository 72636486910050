
export function formatDateTime (inputTime) {
    if(inputTime.toString().length == 10) {
            inputTime = inputTime * 1000;
    }

    var date = new Date(inputTime);
    var now = new Date(Date.parse(new Date()));
    var now_m = now.getMonth() + 1;
    now_m = now_m < 10 ? ('0' + now_m) : now_m;
    var now_d = now.getDate();
    now_d = now_d < 10 ? ('0' + now_d) : now_d;
    var tomorrow =new Date(Date.parse(new Date())+24*3600*1000);
    var tomorrow_m = tomorrow.getMonth() + 1;
    tomorrow_m = tomorrow_m < 10 ? ('0' + tomorrow_m) : tomorrow_m;
    var tomorrow_d = tomorrow.getDate();
    tomorrow_d = tomorrow_d < 10 ? ('0' + tomorrow_d) : tomorrow_d;
    var d_m='';
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    minute = minute < 10 ? ('0' + minute) : minute;
    if(m==now_m && d==now_d){
        d_m='(今天)';
    }else if(m==tomorrow_m && d==tomorrow_d){
        d_m='(明天)';
    }
    return  m + '月' + d + '日'+d_m+ h + ':' + minute ;
}


export function getBRDatePickerData (timeInterval,secs = 0,minsInterval=15) {
    var list = []
    var currDate = new Date()
    function pad2(n) { return n < 10 ? '0' + n : n }

    var l0 = 0,l1 = 0,l2 = 0

    for(var i = 0;i<timeInterval;i++){
        let dateStr = currDate.getFullYear().toString() + "-" + pad2(currDate.getMonth() + 1) + "-" + pad2( currDate.getDate())
        var valDate = pad2(currDate.getMonth() + 1) + "." + pad2( currDate.getDate())
        var valStr = valDate
        i == 0 ? valStr = "今天" : i == 1 ? valStr = "明天" : i == 2 ? valStr = "后天" : valStr = valDate
        switch(currDate.getDay()){
            case 0:
                valStr += "(周日)"
                break
            case 1:
                valStr += "(周一)"
                break
            case 2:
                valStr += "(周二)"
                break
            case 3:
                valStr += "(周三)"
                break
            case 4:
                valStr += "(周四)"
                break
            case 5:
                valStr += "(周五)"
                break
            case 6:
                valStr += "(周六)"
                break
        }

        list.push({
            "level":0,
            "sort":l0++,
            "pkey": "-1",
            "pvalue": "",
            "key": dateStr,
            "value": valStr
        })
        for(var j = 0;j<24;j++){

            var tempDate = new Date(currDate.getFullYear(),currDate.getMonth(),currDate.getDate(),j,60,0)
            if (tempDate.getTime() < new Date().getTime()+secs*1000+minsInterval*60*1000) {
                continue
            }

            var valStr2 = j.toString()+"点"

            list.push({
                "level":1,
                "sort":l1++,
                "pkey": dateStr,
                "pvalue": valStr,
                "key": dateStr +" "+pad2(j),
                "value": valStr2
            })
            
            for(var k = 0;k<60;k+=minsInterval){

                tempDate = new Date(currDate.getFullYear(),currDate.getMonth(),currDate.getDate(),j,k,0)
                if (tempDate.getTime() < new Date().getTime()+secs*1000) {
                    continue
                }

                list.push({
                    "level":2,
                    "sort":l2++,
                    "pkey": dateStr +" "+pad2(j),
                    "pvalue": valStr2,
                    "key": dateStr +" "+pad2(j)+":"+pad2(k),
                    "value": k.toString()+"分"
                })
            }
            
        }
        currDate.setDate(currDate.getDate() + 1)
    }
    return list
}