import { onMounted, reactive, toRefs } from 'vue';
import { ayoInfo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button, CellGroup, Cell, Checkbox, CheckboxGroup } from "vant";
import HeadBlur from "@/components/userpage/HeadBlur.vue";
import GalleryBlock from "@/components/userpage/GalleryBlock.vue";
import InfoBlock from "@/components/userpage/InfoBlock.vue";
import EventBlock from "@/components/userpage/EventBlock.vue";
var route;
export default {
  name: "MooTherapistPage",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    "HeadBlur-Content": HeadBlur,
    "GalleryBlock-Content": GalleryBlock,
    "InfoBlock-Content": InfoBlock,
    "EventBlock-Content": EventBlock
  },
  methods: {
    clickPhoto() {
      dsb.h5({
        "cover": 1
      }, route.params.version, "AyoPhoto", {
        "tuid": route.query.tuid,
        "uid": route.query.uid,
        "token": route.query.token
      });
    },
    nicknameInput() {
      var instance = this;
      dsb.mgo("input", {
        "title": "输入昵称",
        "words": this.nickname,
        "mul": "0",
        "placeholder": "请输入昵称",
        "limit": "12",
        "least": "6"
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.nickname = res.words;
      });
    },
    wordsInput() {
      var instance = this;
      dsb.mgo("input", {
        "title": "输入留言",
        "words": this.words,
        "mul": "1",
        "placeholder": "请输入留言内容",
        "limit": "60",
        "least": "2"
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.words = res.words;
      });
    }
  },
  mounted() {},
  setup() {
    route = useRoute();
    const state = reactive({
      avatar: "",
      nickname: "",
      tuid: "",
      words: "",
      photo_list: [],
      photo_des: "",
      tag_list: [],
      info_list: [],
      event_list: [],
      imMink: {},
      checked: false,
      stature: "",
      liveness: 0,
      notice: 0,
      lbs: 0
    });
    // 调取api获取数据
    const getdata = () => {
      state.tuid = route.query.tuid;
      ayoInfo.pageInfo(route.query.uid, route.query.token, route.query.tuid).then(res => {
        if (res.data.code == 0) {
          state.avatar = res.data.result.avatar;
          state.nickname = res.data.result.nickname;
          state.words = res.data.result.words;
          state.tag_list = res.data.result.tag_list;
          state.photo_list = res.data.result.photo_list;
          state.photo_des = res.data.result.photo_des;
          state.info_list = res.data.result.info_list;
          state.event_list = res.data.result.event_list;
          state.imMink = res.data.result.imMink;
          //res.data.result.h5["reopen"] = "reopen"

          dsb.h5config(res.data.result.h5);
          dsb.dsBridge.register('toolbarClick0', function () {
            dsb.mink(state.imMink);
            return "";
          });
          dsb.dsBridge.register('toolbarClick_like', function () {
            ayoInfo.like(route.query.uid, route.query.token, route.query.tuid, '1').then(res => {
              dsb.h5config(res.data.result.h5);
              dsb.mgo("toast", {
                "word": "关注成功",
                "duration": "1000"
              });
            });
            return "";
          });
          dsb.dsBridge.register('toolbarClick_unlike', function () {
            ayoInfo.like(route.query.uid, route.query.token, route.query.tuid, '0').then(res => {
              dsb.h5config(res.data.result.h5);
              dsb.mgo("toast", {
                "word": "取消关注",
                "duration": "1000"
              });
            });
            return "";
          });
          dsb.dsBridge.register('option', function () {
            dsb.mgo("select", {
              "content": ["拉黑用户", "举报用户(色情、欺诈等)"],
              "title": "请选择操作"
            }, "", function (v) {
              var res = JSON.parse(v);
              console.log(res);
              if (res.index == 0) {
                dsb.mgo("alert", {
                  "title": "操作",
                  "word": "确定拉黑该用户吗？",
                  "bt0": "取消",
                  "bt1": "确定"
                }, "", function (v) {
                  if (JSON.parse(v).button == 1) {
                    dsb.mgo("blacklist", {
                      "uid": route.query.tuid,
                      "set": 1
                    }, "", function () {
                      dsb.mgo("toast", {
                        "word": "拉黑成功",
                        "duration": "2000"
                      });
                      dsb.mgo("back");
                    });
                  }
                });
              } else if (res.index == 1) {
                dsb.mgo("alert", {
                  "title": "操作",
                  "word": "确定举报该用户吗？",
                  "bt0": "取消",
                  "bt1": "确定"
                }, "", function (v) {
                  if (JSON.parse(v).button == 1) {
                    dsb.mgo("report", {
                      "uid": route.query.tuid,
                      "reason": 1
                    }, "", function () {
                      dsb.mgo("toast", {
                        "word": "举报成功",
                        "duration": "2000"
                      });
                    });
                  }
                });
              }
            });
            return "";
          });
        }
      });
      let body = document.querySelector("body");
      body.style.overflow = "hidden";
      body.style.backgroundColor = "rgba(0,0,0,0)";
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};