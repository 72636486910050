import { onMounted, reactive, toRefs } from 'vue';
import { Skeleton } from 'vant';
import { ectinfo } from "@/api/restful";
export default {
  name: 'RichMedia',
  components: {
    [Skeleton.name]: Skeleton
  },
  props: {
    row: {
      type: Number,
      default: () => 10
    },
    id: {
      type: String
    }
  },
  methods: {},
  watch: {},
  setup(props) {
    const state = reactive({
      rmcontent: "",
      loading: true
    });
    // 调取api获取数据

    //document.body.style.backgroundColor="#f2f5f7";
    const getdata = () => {
      ectinfo.rmarticleInfo(props.id).then(res => {
        if (res.code == 0) {
          const edjsHTML = require("editorjs-html");
          const edjsParser = edjsHTML();
          const htmlcontent = edjsParser.parse(res.data.result.content);
          for (var i in htmlcontent) {
            state.rmcontent += htmlcontent[i];
          }
          state.loading = false;
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};