import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6ce5256a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "flex-col items-center"
};
const _hoisted_2 = {
  class: "text_5"
};
const _hoisted_3 = {
  class: "text_2"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex-col equal-division-item", {
      'equal-division-item-checked': $props.checked
    }])
  }, [$props.banner.length != 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(["flex-col items-center text-banner", {
      'text-banner-checked': $props.checked
    }])
  }, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.banner), 1)], 2)])) : _createCommentVNode("", true), _createElementVNode("span", {
    class: _normalizeClass(["text-product", {
      'text-product-checked': $props.checked
    }])
  }, _toDisplayString($props.product), 3), _createElementVNode("div", {
    class: _normalizeClass(["flex-col items-center text-wrapper", {
      'text-wrapper-checked': $props.checked
    }])
  }, [_createElementVNode("span", _hoisted_3, _toDisplayString($props.des), 1)], 2)], 2);
}