import { onMounted, reactive, toRefs, watch } from 'vue';
import { userdata } from "../../api/restful";
import { useRoute } from "vue-router";
import dsb from "../../lib/dsbridge";
import { RadioGroup, Radio, CellGroup, Cell } from 'vant';
var route;
export default {
  name: "ProfileModify",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell
  },
  methods: {
    uploadAvatar() {
      var instance = this;
      dsb.mgo("upload_photo", {
        "square": 1
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.avatar = res.url[0];
        instance.changed = true;
        instance.avatarBase64 = res.base64[0];
      });
    },
    nicknameInput() {
      var instance = this;
      dsb.mgo("input", {
        "title": "输入昵称",
        "words": this.nickname,
        "mul": "0",
        "placeholder": "请输入昵称",
        "limit": "12",
        "least": "2"
      }, "", function (v) {
        var res = JSON.parse(v);
        if (instance.nickname == res.words) {
          return;
        }
        instance.nickname = res.words;
        instance.changed = true;
      });
    },
    datePicker() {
      var instance = this;
      var d = new Date();
      dsb.mgo("date_picker", {
        "type": "YearAndMonth",
        "default": this.dob + '-01-00-00-00',
        "limit": d.getFullYear() - 18 + '-' + d.getMonth() + "-01-00-00-00"
      }, "", function (v) {
        var res = JSON.parse(v);
        if (instance.dob == res.date.replace("-00-00-00", "")) {
          return;
        }
        instance.dob = res.date.replace("-00-00-00", "");
        instance.changed = true;
      });
    },
    doModify() {
      this.changed = false;
      userdata.profileModify(route.query.uid, route.query.token, this.nickname, this.avatar, this.dob);
      if (this.isYo == 1) {
        dsb.mgo("alert", {
          "title": "提示",
          "word": "已提交,审核后将更新",
          "button": "确定"
        });
      } else {
        dsb.mgo("alert", {
          "title": "提示",
          "word": "修改成功",
          "button": "确定"
        });
      }
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      nickname: "",
      avatar: "",
      avatarBase64: "",
      dob: "",
      phone: "",
      inviter: "",
      changed: false,
      isYo: 1
    });
    // 调取api获取数据
    const getdata = () => {
      document.body.style.backgroundColor = "#f2f5f7";
      userdata.profileInfo(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          state.nickname = res.data.result.nickname;
          state.avatar = res.data.result.avatar;
          state.dob = res.data.result.dob;
          state.phone = res.data.result.phone;
          state.inviter = res.data.result.inviter;
          state.isYo = res.data.result.isYo;
          dsb.h5config(res.data.result.h5);
        }
        watch(() => [state.nickname, state.avatar, state.dob], ([nNickname, nAvatar, nDob], [oNickname, oAvatar, oDob]) => {
          if (nNickname != oNickname) {
            state.changed = true;
          }
          if (nAvatar != oAvatar) {
            state.changed = true;
          }
          if (nDob != oDob) {
            state.changed = true;
          }
        }, {
          deep: true
        });
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};