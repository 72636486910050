import { reactive, toRefs } from 'vue';
import { useRoute } from "vue-router";
import { Button } from "vant";
import jsb from "@/lib/jsbridge";
export default {
  name: "AppIntro",
  components: {
    [Button.name]: Button
  },
  methods: {
    shareApp: function () {
      if (this.appid == "") {
        jsb.mgo("toast", {
          "word": "APP暂未上架"
        });
      } else {
        jsb.mgo("clipboard", {
          "word": "https://apps.apple.com/cn/app/id" + this.appid
        });
        jsb.mgo("toast", {
          "word": "已复制应用下载地址"
        });
      }
    }
  },
  setup() {
    const state = reactive({
      logo: "",
      uid: "",
      appid: ""
    });
    // 调取api获取数据
    var route = useRoute();
    state.logo = route.query.logo;
    state.uid = route.query.uid;
    if (route.query.appid) {
      state.appid = route.query.appid;
    }
    return {
      ...toRefs(state)
    };
  }
};