import "core-js/modules/es.array.unshift.js";
import { onMounted, reactive, toRefs } from 'vue';
import { moo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button, Divider, Step, Steps } from "vant";
import OrderInfo from '@/components/moo/OrderInfo.vue';
import OrderStatus from '@/components/moo/order/status.vue';
import { formatDateTime } from "@/lib/tool";
export default {
  name: "MooOrderInfo",
  components: {
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [OrderInfo.name]: OrderInfo,
    [OrderStatus.name]: OrderStatus
  },
  methods: {
    time2str: function (t) {
      return formatDateTime(t);
    },
    clickMink: function (mink) {
      dsb.mink(mink);
    },
    contactTherapist: function () {
      var instance = this;
      dsb.mgo("select", {
        "content": ["APP内联系", "电话联系", "技师主页"],
        "title": "请选择操作"
      }, "", function (v) {
        var res = JSON.parse(v);
        if (res.index == 0) {
          dsb.mink(instance.therapist.im);
        } else if (res.index == 1) {
          dsb.mgo("scheme", {
            "url": "tel://" + instance.therapist.phone
          });
        } else if (res.index == 2) {
          dsb.mink(instance.therapist.homepage);
        }
      });
    },
    statusButton: function () {
      var instance = this;
      if (this.statusStr == "order_picked") {
        dsb.mgo("alert", {
          "title": "操作",
          "word": "确定技师到达,服务开始吗？",
          "bt0": "取消",
          "bt1": "确定"
        }, "", function (v) {
          if (JSON.parse(v).button == 1) {
            dsb.mgo("setLoading", {
              "act": 1
            });
            moo.orderStart(instance.id).then(res => {
              dsb.mgo("setLoading", {
                "act": 0
              });
              if (res.data.code == 0) {
                instance.statusStr = "order_start";
                instance.serviceTimeStart = parseInt(Date.parse(new Date()) / 1000);
                instance.serviceTimeEnd = instance.serviceTimeStart + instance.service_minutes * 60;
                instance.log.unshift({
                  label: "用户确认开始服务",
                  time: instance.serviceTimeStart
                });
              }
            });
          }
        });
      } else {
        this.clickMink(this.statusMink);
      }
    }
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      loading: true,
      id: route.query.id,
      log: [],
      service_image: "",
      service_cost: 0,
      service_title: "",
      service_minutes: 0,
      order_gender: 0,
      order_therapistFlag: 0,
      address: {},
      availableUnixTime: 0,
      orderTimeOutUnixTime: 0,
      serviceTimeStart: 0,
      serviceTimeEnd: 0,
      serviceTimeFinish: 0,
      therapist: {},
      cs: {},
      statusStr: "",
      statusMink: {}
    });

    // 调取api获取数据
    const getdata = () => {
      moo.orderInfo(route.query.id).then(res => {
        if (res.data.code == 0) {
          state.log = res.res.log;
          state.service_image = res.res.service_image;
          state.service_cost = res.res.service_cost;
          state.service_title = res.res.service_title;
          state.service_minutes = res.res.service_minutes;
          state.address = res.res.address;
          state.order_gender = res.res.gender;
          state.order_therapistFlag = res.res.therapistFlag;
          state.statusStr = res.res.statusStr;
          state.statusMink = res.res.statusMink;
          state.availableUnixTime = res.res.availableUnixTime;
          state.orderTimeOutUnixTime = res.res.orderTimeOutUnixTime;
          state.serviceTimeStart = res.res.serviceTimeStart;
          state.serviceTimeEnd = res.res.serviceTimeEnd;
          state.serviceTimeFinish = res.res.serviceTimeFinish;
          state.therapist = res.res.therapist;
          state.cs = res.res.cs;
          state.loading = false;
          dsb.h5config({
            "name": "OrderInfo"
          });
        }
      });
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};