import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e852f36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex-row list-item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_col = _resolveComponent("van-col");
  const _component_van_row = _resolveComponent("van-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_row, {
    gutter: "20"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_col, {
      span: "8"
    }, {
      default: _withCtx(() => [_createTextVNode("span: 8")]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "8"
    }, {
      default: _withCtx(() => [_createTextVNode("span: 8")]),
      _: 1
    }), _createVNode(_component_van_col, {
      span: "8"
    }, {
      default: _withCtx(() => [_createTextVNode("span: 8")]),
      _: 1
    })]),
    _: 1
  })]);
}