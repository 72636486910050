import { onMounted, reactive, toRefs } from 'vue';
export default {
  name: "OtherMember",
  components: {},
  methods: {},
  setup() {
    const state = reactive({
      str: ""
    });
    const getdata = () => {
      state.str = `
本规则被视为《用户协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。如本规则与《用户协议》存在冲突，则以本规则为准。

您点击订阅、开通及使用会员自动续费服务（以下称“本自动续费服务”），则视为您同意本规则。

会员系列包括：

1.订阅服务：
a）VIP连续包月(1个月)
b）SVIP连续包季(3个月)

2.订阅价格：
a）VIP连续包月：连续包月产品为168元/月
b）VIP连续包季(3个月)：连续包季产品为283元/季

您理解并同意：

1. 订阅期：1个月、3个月（以订阅页面设置为准），会员可自行选择。

2. 您确认并同意，本司拥有调整服务价格的权利。如本司拟对您开通自动续费服务时的服务价格做出调整，本司将以适当方式（包括但不限于APP公告、短信等）事先通知您。如您不同意服务价格调整，您可以根据本规则取消本自动续费服务；如您未取消本自动续费服务，则相关主体后续代扣的费用将以调整后的服务费用为准；如您在订阅期到期前24小时内取消，则相关主体将可能会按照调整后的服务费用扣除下一个订阅期的服务费用后停止代扣服务。在本自动续费服务取消后，如您再次开通自动续费服务，相关主体自动代扣的服务费用以届时页面显示的服务费用为准。

3.自动续费：苹果iTunes账户会在到期前24小时内扣费，扣费成功后订阅周期顺延一个订阅周期

4.关闭服务：您可以在苹果手机“设置” --> 进入“iTunes Store 与 App Store”-->点击 “Apple ID”，选择"查看Apple ID"，进入"账户设置"页面，点击“订阅”，管理自动订阅服务，如需取消，每个计费周期结束前24小时关闭即可，到期前24小时内则不再扣费

5.本司亦可以根据运营策略需要而停止继续向您提供自动续费服务，您的本司会员服务期限自当前服务周期届满之日起终止。

6.隐私协议：https://www.tongyouapp.com/privacy

`;
    };
    onMounted(getdata);
    return {
      ...toRefs(state)
    };
  }
};