import { onMounted, reactive, toRefs, ref } from 'vue';
import { member, qb, base } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button, RadioGroup, Radio } from "vant";
import MemberPrivilege from "./Privilege";
export default {
  name: "MooMemberMain",
  components: {
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [MemberPrivilege.name]: MemberPrivilege
  },
  methods: {
    clickMember: function () {
      if (this.isIos) {
        dsb.mgo("setLoading", {
          "act": 1,
          "timeout": 60
        });
        dsb.mgo("pgc", {
          "id": this.left.id,
          "js": "iap_callback"
        });
      } else {
        this.payload.amount = 16800;
        this.payload.channel = this.channel;
        this.payload.type = "member";
        dsb.mgo("setLoading", {
          "act": 1,
          "timeout": 5
        });
        base.req(this.api, {
          params: this.payload
        }).then(res => {
          if (res.code == 0) {
            dsb.mgo("scheme", {
              "url": res.res.link
            });
          } else {
            dsb.mgo("setLoading", {
              "act": 0
            });
          }
        });
      }
    },
    clickRestore: function () {
      dsb.mgo("toast", {
        "word": "执行恢复"
      });
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 40
      });
      dsb.mgo("pgc", {
        "id": "",
        "type": "restore",
        "js": "iap_callback"
      });
    },
    clicArticle: function (rmid) {
      var link = "https://www.tongyouapp.com/yospa/privacy";
      if (rmid == "member") {
        link = "https://www.tongyouapp.com/yospa/member";
      }
      dsb.mgo("browser", {
        "url": link
      });
    }
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      channel: "WX_APP",
      avatar: "",
      nickname: "",
      memberVaildTime: 0,
      left: {},
      api: "",
      payload: {},
      isIos: true
    });
    dsb.dsBridge.register('iap_callback', function (x) {
      if (x.status == 0) {
        if (x.data && x.data.length > 0) {
          var appinfo = dsb.mgo("appinfo", {});
          member.memberIapCheck(x.data, appinfo.res).then(() => {
            dsb.mgo("setLoading", {
              "act": 0
            });
            member.memberInfo(state.query.uid, state.query.token).then(res => {
              if (res.data.code == 0) {
                state.memberVaildTime = res.data.result.memberVaildTime;
                dsb.mgo("toast", {
                  "word": "操作成功"
                });
              }
            });
          });
        }
      } else if (x.status == -1) {
        dsb.mgo("setLoading", {
          "act": 0
        });
        dsb.mgo("toast", {
          "word": "操作取消"
        });
      } else {
        dsb.mgo("setLoading", {
          "act": 0
        });
        dsb.mgo("alert", {
          "title": "提示",
          "word": "充值失败,请稍后重试,或联系客服,错误码：" + x.status,
          "button": "确定"
        });
      }
    });

    // 调取api获取数据
    const getdata = () => {
      member.memberInfo(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          state.nickname = res.data.result.nickname;
          state.avatar = res.data.result.avatar;
          state.memberVaildTime = res.data.result.memberVaildTime;
          state.left = res.data.result.left;
        }
      });
      qb.qbCz("会员").then(res => {
        if (res.data.code == 0) {
          state.api = res.res.api;
          state.payload = res.res.payload;
        }
      });
    };
    state.query = route.query;
    onMounted(getdata);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    const activeName = ref('0');
    return {
      ...toRefs(state),
      themeVars,
      activeName
    };
  }
};