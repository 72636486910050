export default {
  name: 'RadioPicker',
  components: {},
  props: {
    picked: {
      type: Number,
      default: () => 0
    },
    list: Object,
    title: String,
    clickFunc: Function
  },
  methods: {
    clickOption: function (index) {
      this.pick = index;
      this.clickFunc(index);
    }
  },
  watch: {},
  data: function () {
    return {
      pick: this.picked
    };
  }
};