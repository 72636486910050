

import { createApp } from "vue";
import router from "./router/index.js";
import App from "./App.vue";
import "../public/rem"
import { ConfigProvider,Lazyload,Col,Row  } from 'vant';
import Vconsole from 'vconsole';
import "@/static/theme.css"

import utils from '@/lib/utils.js';
if(utils.getUrlKey('dev',0)  == 1){
    const vConsole = new Vconsole();
    vConsole.setSwitchPosition(20,120)
}
const app = createApp(App)
app.use(router);
app.use(ConfigProvider).use(Lazyload, {lazyComponent: true,});
app.use(Col);
app.use(Row);

app.mount("#app");





router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    var theme = utils.getUrlKey('pkg',"youthyo")
    /*
    if(to.params.version&&to.params.version!=""){
        var num = to.params.version.split(".")[0]
        if(num=="0"){
            theme = "default"
        }
    }
    */
    
    
    document.documentElement.setAttribute('data-theme', theme)
next()
})

