export default {
  name: 'InfoBlock',
  components: {},
  props: {
    label: {
      type: String,
      default: () => ''
    },
    des: String,
    icon: String
  },
  methods: {},
  watch: {}
};