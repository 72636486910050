import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-11a960b6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "global_margin"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_InfoBlock = _resolveComponent("InfoBlock");
  const _component_upload_cross = _resolveComponent("upload-cross");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_InfoBlock, null, {
    label: _withCtx(() => [_createVNode(_component_van_icon, {
      width: "0.20rem",
      height: "0.20rem",
      name: "warning",
      color: "#1989fa"
    }), _createTextVNode(" 所上传生活照将经过审核，需要满足各项条件，点击查看 >> ")]),
    _: 1
  }), _createVNode(_component_upload_cross, {
    data: _ctx.list,
    limit: "20",
    manager: "true"
  }, null, 8, ["data"])]);
}