import "core-js/modules/es.array.push.js";
export default {
  name: "InfoBlock",
  props: {
    list: {
      default: () => []
    }
  },
  components: {},
  methods: {
    strlen(str) {
      var len = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        //单字节加1 
        if (c >= 0x0001 && c <= 0x007e || 0xff60 <= c && c <= 0xff9f) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    }
  },
  data: function () {
    var tmpinfolist = this.list;
    var tmplist = [];
    for (var i in tmpinfolist) {
      const l = this.strlen(tmpinfolist[i].s);
      if (l > 18) {
        tmpinfolist[i].c = "xx_x";
        tmpinfolist[i].v = 3;
      } else if (l > 12) {
        tmpinfolist[i].c = "xx_m";
        tmpinfolist[i].v = 2;
      } else {
        tmpinfolist[i].c = "xx_s";
        tmpinfolist[i].v = 1;
      }
      tmplist.push({
        "content": [parseInt(i)],
        "total": tmpinfolist[i].v
      });
    }
    function changeList(list) {
      var i = 0;
      var i2 = 0;
      for (i = 0; i < list.length - 1; i++) {
        if (list[i].total == 1) {
          for (i2 = i + 1; i2 < list.length; i2++) {
            if (list[i2].total == 2) {
              list[i].content = list[i].content.concat(list[i2].content);
              list[i].total += list[i2].total;
              list.splice(i2, 1);
              return list;
            }
          }
        } else if (list[i].total == 2) {
          for (i2 = i + 1; i2 < list.length; i2++) {
            if (list[i2].total == 1) {
              list[i].content = list[i].content.concat(list[i2].content);
              list[i].total += list[i2].total;
              list.splice(i2, 1);
              return list;
            }
          }
        }
        if (list[i].total == 1) {
          for (i2 = i + 1; i2 < list.length; i2++) {
            if (list[i2].total == 1) {
              list[i].content = list[i].content.concat(list[i2].content);
              list[i].total += list[i2].total;
              list.splice(i2, 1);
              return list;
            }
          }
        }
      }
      return list;
    }
    i = 0;
    while (i < 30) {
      i++;
      var len = tmplist.length;
      tmplist = changeList(tmplist);
      if (len == tmplist.length) {
        break;
      }
    }
    var i2 = 0;
    var infolist = [];
    for (i = 0; i < tmplist.length; i++) {
      for (i2 = 0; i2 < tmplist[i].content.length; i2++) {
        infolist.push(tmpinfolist[tmplist[i].content[i2]]);
      }
    }
    return {
      infolist
    };
  },
  watch: {
    list(nv) {
      this.infolist = nv;
      for (var i in this.infolist) {
        this.infolist[i].c = "xx_s";
      }
    }
  }
};