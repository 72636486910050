import "core-js/modules/es.array.push.js";
import { Button } from "vant";
import dsb from "@/lib/dsbridge";
export default {
  name: "PickList",
  props: {
    picklimit: {
      type: Number,
      default: () => 0
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {
    [Button.name]: Button
  },
  methods: {
    clickIt(index) {
      var pick = [];
      for (var x in this.list) {
        if (this.list[x].c == 1) {
          pick.push(parseInt(x));
        }
      }
      if (this.list[index].c == 0) {
        if (this.picklimit != 0) {
          if (pick.length >= this.picklimit) {
            dsb.mgo("toast", {
              "word": "最多选择" + this.picklimit.toString() + "项"
            });
            return;
          }
        }
      }
      this.list[index].c = this.list[index].c == 1 ? 0 : 1;
    }
  },
  data: function () {
    return {
      list: this.items
    };
  },
  watch: {
    items(nv) {
      this.list = nv;
    }
  }
};