import "core-js/modules/es.array.push.js";
import { onMounted, reactive, toRefs, watch } from 'vue';
import { ayoInfo, userdata } from "../../api/restful";
import useAyoYouthyoRestful from '@/api/ayo-youthyo';
import { useRoute } from "vue-router";
import dsb from "../../lib/dsbridge";
import { Button, CellGroup, Cell, Checkbox, CheckboxGroup } from "vant";
import PickList from "@/components/common/list/pick.vue";
import PhotoCell from "@/components/PhotoCell.vue";
import InputCell from "@/components/InputCell.vue";
import InfoBlock from "@/components/common/block/info.vue";
var route;
export default {
  name: "AyoApply",
  components: {
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [InfoBlock.name]: InfoBlock,
    [PickList.name]: PickList,
    [PhotoCell.name]: PhotoCell,
    'input-cell': InputCell
  },
  mounted() {},
  methods: {
    uploadAvatar() {
      var instance = this;
      dsb.mgo("upload_photo", {
        "square": 1
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.avatar = res.url[0];
      });
    },
    nicknameInput() {
      var instance = this;
      dsb.mgo("input", {
        "title": "输入昵称",
        "words": this.nickname,
        "mul": "0",
        "placeholder": "请输入昵称",
        "limit": "12",
        "least": "6"
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.nickname = res.words;
      });
    },
    wordsInput() {
      var instance = this;
      dsb.mgo("input", {
        "title": "输入留言",
        "words": this.words,
        "mul": "1",
        "placeholder": "请输入留言内容",
        "limit": "60",
        "least": "2"
      }, "", function (v) {
        var res = JSON.parse(v);
        instance.words = res.words;
      });
    },
    livenessCheck() {
      var instance = this;
      dsb.mgo("liveness", {
        "check": "ayoapply",
        "nolink": "0"
      }, "", function (v) {
        var res = JSON.parse(v);
        if (res.status == 0) {
          instance.liveness = 1;
        }
      });
    },
    goback() {
      dsb.mgo("back", {
        "ts": "1"
      });
    }
  },
  setup() {
    route = useRoute();
    const state = reactive({
      waitforreview: false,
      h5config: {},
      h5toolbar: {},
      pick_list: [],
      photo_list: [],
      checked: false,
      avatar: "",
      nickname: "",
      words: "",
      stature: "",
      liveness: 0,
      notice: 0,
      lbs: 0,
      lat: 0,
      lon: 0,
      deviceToken: "",
      profileChange: false,
      query: {}
    });
    // 调取api获取数据
    const noticeRequest = a => {
      var res = dsb.mgo("notice_info", {
        "shock": 0
      });
      if (res) {
        if (res.status == 1) {
          state.deviceToken = res.res.deviceToken;
          state.notice = res.res.notice;
          if (state.notice == 0 && a == 1) {
            dsb.mgo("notice_request", {
              "title": "提示",
              "word": "需要在设置中打开位置",
              "bt0": "取消",
              "bt1": "设置"
            }, "", function (v) {
              var res = JSON.parse(v);
              if (res.state == 0) {
                state.notice = 1;
              }
            });
          }
        }
      }
    };
    const lbsRequest = a => {
      var res = dsb.mgo("lbs_info", {
        "shock": 0
      });
      if (res) {
        if (res.status === 1) {
          state.lat = res.res.lat;
          state.lon = res.res.lon;
          state.lbs = res.res.lbs;
          if (state.lbs == 0 && a == 1) {
            dsb.mgo("lbs_request", {
              "title": "提示",
              "word": "需要在设置中打开位置",
              "bt0": "取消",
              "bt1": "设置"
            }, "", function (v) {
              var res = JSON.parse(v);
              if (res.state == 0) {
                state.lbs = 1;
              }
            });
          }
        }
      }
    };
    const waitpage = t => {
      state.waitforreview = t;
      dsb.mgo("setBarVisible", {
        "visible": t ? 0 : 1,
        'pos': 3
      });
      if (!t) {
        dsb.h5config(state.h5config);
      }
    };
    const getdata = () => {
      dsb.dsBridge.register('toolbarClick', function () {
        var pick = {};
        //var instance = this
        for (var x in state.pick_list) {
          var a = state.pick_list[x];
          pick[a["key"]] = [];
          for (var l in a["list"]) {
            if (a["list"][l]["c"] == 1) {
              pick[a["key"]].push(a["list"][l]["t"]);
            }
          }
        }
        var apidata = {
          "words": state.words,
          "stature": state.stature.replace("cm", ""),
          "pick": pick
        };
        var msg = "";
        if (state.deviceToken == "") {
          msg = "请打开消息通知";
          msg = "";
        }
        if (state.stature.replace("cm", "") == "") {
          msg = "请提供身高信息";
        }
        if (state.words == "") {
          msg = "请填写留言信息";
        }
        if (state.notice == 0) {
          msg = "请打开消息通知\n如果已打开请再点击一次获取";
        }
        if (state.liveness == 0) {
          msg = "请打开消息通知\n如果已认证请再点击一次获取";
        }

        // if(state.lbs==0){
        //     msg = "请打开消息通知\n如果已打开请再点击一次获取"
        // }
        if (state.photo_list.length == 0) {
          msg = "请上传一些照片";
        }
        if (msg != "") {
          dsb.mgo("alert", {
            "title": "提示",
            "word": msg,
            "bt1": "确定"
          });
          return;
        }
        if (state.profileChange) {
          state.profileChange = true;
          userdata.profileModify(route.query.uid, route.query.token, state.nickname, state.avatar, "");
        }
        useAyoYouthyoRestful.apply(apidata).then(res => {
          if (res.code == 0) {
            if (res.data.result.errcode == 0) {
              waitpage(true);
              state.h5config["toolbar2"]["label"] = "资料审核中";
              state.h5config["toolbar2"]["labelBottom"] = "点击修改申请资料";
              state.h5config["toolbar2"]["labelBottomColor"] = "ffffff";
              state.h5config["toolbar2"]["labelBottomFontsize"] = "12";
              state.h5config["toolbar2"]["bgColor"] = getComputedStyle(document.documentElement).getPropertyValue('--van-app-gray').replace("#", "");
              state.h5config["toolbar2"]["buttonColor"] = state.h5config["toolbar2"]["bgColor"];
            }
          } else {
            dsb.mgo("toast", {
              "word": res.msg
            });
          }
        });
        return "";
      });
      dsb.dsBridge.register('updatePhoto', function (v) {
        state.photo_list = v;
        return "";
      });
      ayoInfo.applyData(route.query.uid, route.query.token).then(res => {
        if (res.data.code == 0) {
          state.pick_list = res.data.result.pick_list;
          state.photo_list = res.data.result.photo_list;
          state.avatar = res.data.result.avatar;
          state.nickname = res.data.result.nickname;
          state.words = res.data.result.words;
          state.stature = res.data.result.stature;
          if (state.stature != "") {
            state.stature += "cm";
          }
          state.liveness = res.data.result.liveness;
          state.h5config = res.data.result.h5;
          console.log(res.data.result.status);
          if (res.data.result.status == 0) {
            state.h5config["toolbar2"]["label"] = "资料审核中";
            state.h5config["toolbar2"]["labelBottom"] = "点击修改申请资料";
            state.h5config["toolbar2"]["labelBottomColor"] = "ffffff";
            state.h5config["toolbar2"]["labelBottomFontsize"] = "12";
            state.h5config["toolbar2"]["bgColor"] = getComputedStyle(document.documentElement).getPropertyValue('--van-app-gray').replace("#", "");
            state.h5config["toolbar2"]["buttonColor"] = state.h5config["toolbar2"]["bgColor"];
          } else if (res.data.result.status == 1) {
            state.h5config["toolbar2"]["label"] = "已通过";
            state.h5config["toolbar2"]["labelBottom"] = "点击修改申请资料";
            state.h5config["toolbar2"]["labelBottomColor"] = "ffffff";
            state.h5config["toolbar2"]["labelBottomFontsize"] = "12";
            state.h5config["toolbar2"]["bgColor"] = getComputedStyle(document.documentElement).getPropertyValue('--van-app-gold').replace("#", "");
            state.h5config["toolbar2"]["buttonColor"] = state.h5config["toolbar2"]["bgColor"];
          } else if (res.data.result.status == -1) {
            state.h5config["toolbar2"]["label"] = "申 请";
            state.h5config["toolbar2"]["labelBottom"] = "点击提交申请资料";
            state.h5config["toolbar2"]["labelBottomColor"] = "ffffff";
            state.h5config["toolbar2"]["labelBottomFontsize"] = "12";
            state.h5config["toolbar2"]["bgColor"] = getComputedStyle(document.documentElement).getPropertyValue('--van-app-main').replace("#", "");
            state.h5config["toolbar2"]["buttonColor"] = state.h5config["toolbar2"]["bgColor"];
          } else if (res.data.result.status != 1) {
            state.h5config["toolbar2"]["label"] = "未通过或被关闭";
            state.h5config["toolbar2"]["labelBottom"] = "点击再次提交资料";
            state.h5config["toolbar2"]["labelBottomColor"] = "ffffff";
            state.h5config["toolbar2"]["labelBottomFontsize"] = "12";
            state.h5config["toolbar2"]["bgColor"] = getComputedStyle(document.documentElement).getPropertyValue('--van-app-female').replace("#", "");
            state.h5config["toolbar2"]["buttonColor"] = state.h5config["toolbar2"]["bgColor"];
          }
          dsb.h5config(state.h5config);
          if (res.data.result.status == 0) {
            waitpage(true);
          }
          state.h5toolbar = state.h5config.toolbar2;
        }
        noticeRequest(0);
        lbsRequest(0);
        watch(() => [state.nickname, state.avatar], ([nNickname, nAvatar], [oNickname, oAvatar]) => {
          if (nNickname != oNickname || nAvatar != oAvatar) {
            state.profileChange = true;
          }
        }, {
          deep: true
        });
      });
    };
    state.query = route.query;
    state.query.version = route.params.version;
    onMounted(getdata);
    return {
      ...toRefs(state),
      noticeRequest,
      lbsRequest,
      waitpage
    };
  }
};