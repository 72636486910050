import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_cell_group, {
    border: false
  }), _createVNode(_component_van_cell, {
    title: "h5api"
  }), _createVNode(_component_van_cell, {
    checked: "",
    title: "连续调用",
    onClick: _cache[0] || (_cache[0] = $event => $options.mgo_check_double())
  }), _createVNode(_component_van_cell, {
    checked: "",
    title: "测试",
    onClick: _cache[1] || (_cache[1] = $event => $options.justTest())
  }), _createVNode(_component_van_cell, {
    title: "popcard",
    onClick: _cache[2] || (_cache[2] = $event => $options.mgo_popcard()),
    label: ":::::::底部卡片"
  }), _createVNode(_component_van_cell, {
    title: "appinfo",
    onClick: _cache[3] || (_cache[3] = $event => $options.domgo('appinfo', {})),
    label: ":::::::commonInfo"
  }), _createVNode(_component_van_cell, {
    title: "alert",
    onClick: _cache[4] || (_cache[4] = $event => $options.mgo('alert', {
      'title': 'title',
      'word': 'word',
      'bt1': 'bt1'
    })),
    label: ":::::::弹出alert"
  }), _createVNode(_component_van_cell, {
    title: "alert",
    value: "确认",
    onClick: _cache[5] || (_cache[5] = $event => $options.mgo('alert', {
      'title': 'title',
      'word': 'word',
      'bt1': 'bt1',
      'bt0': 'XX'
    }))
  }), _createVNode(_component_van_cell, {
    title: "alert",
    value: "card",
    onClick: _cache[6] || (_cache[6] = $event => $options.mgo('alert', {
      'style': 'card',
      'title': 'title',
      'word': 'word',
      'bt1': 'bt1',
      'bt0': 'XX'
    }))
  }), _createVNode(_component_van_cell, {
    title: "alert",
    value: "card图片",
    onClick: _cache[7] || (_cache[7] = $event => $options.mgo('alert', {
      'style': 'card',
      'title': 'title',
      'word': 'word',
      'bt1': 'bt1',
      'image': 'imlist_black',
      'bg': 'dark'
    }))
  }), _createVNode(_component_van_cell, {
    title: "blank",
    value: _ctx.soundId,
    onClick: _cache[8] || (_cache[8] = $event => $options.mgo_blank()),
    label: ":::::::白板"
  }, null, 8, ["value"]), _createVNode(_component_van_cell, {
    title: "back",
    value: "未设置ts",
    onClick: _cache[9] || (_cache[9] = $event => $options.mgo('back', {})),
    label: ":::::::返回上一页"
  }), _createVNode(_component_van_cell, {
    title: "back",
    value: "ts=1",
    onClick: _cache[10] || (_cache[10] = $event => $options.mgo('back', {
      'ts': '1'
    }))
  }), _createVNode(_component_van_cell, {
    title: "back",
    value: "ts=2",
    onClick: _cache[11] || (_cache[11] = $event => $options.mgo('back', {
      'ts': '1'
    }))
  }), _createVNode(_component_van_cell, {
    title: "browser",
    value: "",
    onClick: _cache[12] || (_cache[12] = $event => $options.mgo('browser', {
      'title': 'tishi',
      'word': '打开网页',
      'bt0': '取消',
      'bt1': '打开',
      'link': 'https://www.baidu.com'
    })),
    label: ":::::::打开网页"
  }), _createVNode(_component_van_cell, {
    title: "browser",
    value: "直接打开",
    onClick: _cache[13] || (_cache[13] = $event => $options.mgo('browser', {
      'link': 'https://www.baidu.com'
    }))
  }), _createVNode(_component_van_cell, {
    title: "blacklist",
    value: "用户主页测试，拉黑",
    onClick: _cache[14] || (_cache[14] = $event => $options.mgo('blacklist', {
      'uid': '27288907',
      'set': 1
    })),
    label: ":::::::拉黑"
  }), _createVNode(_component_van_cell, {
    title: "blacklist",
    value: "用户主页测试，取消",
    onClick: _cache[15] || (_cache[15] = $event => $options.mgo('blacklist', {
      'uid': '27288907',
      'set': 0
    })),
    label: ":::::::拉黑"
  }), _createVNode(_component_van_cell, {
    title: "clipboard",
    value: "写入",
    onClick: _cache[16] || (_cache[16] = $event => $options.mgo('clipboard', {
      'word': '复制成功'
    })),
    label: ":::::::复制版操作"
  }), _createVNode(_component_van_cell, {
    title: "clipboard",
    value: "读取",
    onClick: _cache[17] || (_cache[17] = $event => $options.domgo('clipboard', {
      'read': ''
    }))
  }), _createVNode(_component_van_cell, {
    title: "city",
    onClick: _cache[18] || (_cache[18] = $event => $options.mgo('city', {})),
    label: ":::::::选择城市"
  }), _createVNode(_component_van_cell, {
    title: "city",
    value: "默认内容",
    onClick: _cache[19] || (_cache[19] = $event => $options.mgo('city', {
      'default': '三亚'
    }))
  }), _createVNode(_component_van_cell, {
    title: "cacheClear",
    value: "",
    onClick: _cache[20] || (_cache[20] = $event => $options.mgo('cacheClear', {
      'action': 1
    })),
    label: ":::::::清楚缓存"
  }), _createVNode(_component_van_cell, {
    title: "cacheClear",
    value: "缓存大小",
    onClick: _cache[21] || (_cache[21] = $event => $options.mgo('cacheClear', {
      'action': 0
    }))
  }), _createVNode(_component_van_cell, {
    title: "date",
    onClick: _cache[22] || (_cache[22] = $event => $options.mgo_picker()),
    label: ":::::::自定义选择"
  }), _createVNode(_component_van_cell, {
    title: "date_picker",
    onClick: _cache[23] || (_cache[23] = $event => $options.mgo_date_picker()),
    label: ":::::::选择日期"
  }), _createVNode(_component_van_cell, {
    title: "date_picker",
    value: "预约时间",
    onClick: _cache[24] || (_cache[24] = $event => $options.mgo_date_picker2())
  }), _createVNode(_component_van_cell, {
    title: "gotoAppSetting",
    onClick: _cache[25] || (_cache[25] = $event => $options.mgo('gotoAppSetting')),
    label: ":::::::去设置页"
  }), _createVNode(_component_van_cell, {
    title: "input",
    onClick: _cache[26] || (_cache[26] = $event => $options.mgo('input', {
      'title': '标题',
      'words': '默认内容',
      'placeholder': '设置placeholder',
      'mul': '1',
      'limit': '12',
      'least': '6'
    })),
    label: ":::::::输入内容"
  }), _createVNode(_component_van_cell, {
    title: "logout",
    value: "fast",
    onClick: _cache[27] || (_cache[27] = $event => $options.mgo_logout()),
    label: ":::::::登出账号"
  }), _createVNode(_component_van_cell, {
    title: "lbs_info",
    onClick: _cache[28] || (_cache[28] = $event => $options.domgo('lbs_info', {})),
    label: ":::::::坐标信息"
  }), _createVNode(_component_van_cell, {
    title: "lbs_request",
    onClick: _cache[29] || (_cache[29] = $event => $options.mgo('lbs_request', {})),
    label: ":::::::坐标请求"
  }), _createVNode(_component_van_cell, {
    title: "liveness",
    onClick: _cache[30] || (_cache[30] = $event => $options.mgo('liveness', {
      'check': 'liveness',
      'nolink': '1'
    })),
    label: ":::::::人脸识别"
  }), _createVNode(_component_van_cell, {
    title: "listapi",
    onClick: _cache[31] || (_cache[31] = $event => $options.mgo_listapi()),
    label: ":::::::commonInfo"
  }), _createVNode(_component_van_cell, {
    title: "localstorage",
    value: "储存",
    onClick: _cache[32] || (_cache[32] = $event => $options.domgo('localstorage', {
      'save': 1,
      'key': 'test'
    })),
    label: ":::::::本地存储"
  }), _createVNode(_component_van_cell, {
    title: "localstorage",
    value: "储存字典",
    onClick: _cache[33] || (_cache[33] = $event => $options.domgo('localstorage', {
      'save': {
        'check': '1'
      },
      'key': 'test'
    }))
  }), _createVNode(_component_van_cell, {
    title: "localstorage",
    value: "读取",
    onClick: _cache[34] || (_cache[34] = $event => $options.domgo('localstorage', {
      'get': 1,
      'key': 'test'
    }))
  }), _createVNode(_component_van_cell, {
    title: "mediaBrowser",
    value: "",
    onClick: _cache[35] || (_cache[35] = $event => $options.mgo_mediaBrowser(0, 0, 0)),
    label: ":::::::展示媒体"
  }), _createVNode(_component_van_cell, {
    title: "mediaBrowser",
    value: "开启保护",
    onClick: _cache[36] || (_cache[36] = $event => $options.mgo_mediaBrowser(1, 1, 1)),
    label: ""
  }), _createVNode(_component_van_cell, {
    title: "mustRefresh",
    value: "刷新首页",
    onClick: _cache[37] || (_cache[37] = $event => $options.mgo('mustRefresh', {
      'index': 0
    })),
    label: ":::::::页面要求刷新"
  }), _createVNode(_component_van_cell, {
    title: "mustRefresh",
    value: "刷新邀请",
    onClick: _cache[38] || (_cache[38] = $event => $options.mgo('mustRefresh', {
      'index': 1
    })),
    label: ""
  }), _createVNode(_component_van_cell, {
    title: "notice_info",
    onClick: _cache[39] || (_cache[39] = $event => $options.domgo('notice_info', {})),
    label: ":::::::请求信息"
  }), _createVNode(_component_van_cell, {
    title: "notice_request",
    onClick: _cache[40] || (_cache[40] = $event => $options.mgo('notice_request', {})),
    label: ":::::::请求推送"
  }), _createVNode(_component_van_cell, {
    title: "refresh",
    onClick: _cache[41] || (_cache[41] = $event => $options.mgo_refresh()),
    label: ":::::::刷新"
  }), _createVNode(_component_van_cell, {
    title: "setLoading",
    onClick: _cache[42] || (_cache[42] = $event => $options.mgo_setLoading()),
    label: ":::::::显示loading"
  }), _createVNode(_component_van_cell, {
    title: "select",
    value: "5个",
    onClick: _cache[43] || (_cache[43] = $event => $options.mgo('select', {
      'content': ['00', '11', '22', '33', '44', '55']
    })),
    label: ":::::::复制版操作"
  }), _createVNode(_component_van_cell, {
    title: "select",
    value: "11个",
    onClick: _cache[44] || (_cache[44] = $event => $options.mgo('select', {
      'content': ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99', '1010']
    }))
  }), _createVNode(_component_van_cell, {
    title: "select",
    value: "默认3",
    onClick: _cache[45] || (_cache[45] = $event => $options.mgo('select', {
      'default': '3',
      'content': ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99', '1010']
    }))
  }), _createVNode(_component_van_cell, {
    title: "setBarVisible",
    value: "隐藏title",
    onClick: _cache[46] || (_cache[46] = $event => $options.mgo('setBarVisible', {
      'visible': 0,
      'pos': 1
    })),
    label: ":::::::设置头部、工具栏可见"
  }), _createVNode(_component_van_cell, {
    title: "setBarVisible",
    value: "可见title",
    onClick: _cache[47] || (_cache[47] = $event => $options.mgo('setBarVisible', {
      'visible': 1,
      'pos': 1
    }))
  }), _createVNode(_component_van_cell, {
    title: "setBarVisible",
    value: "隐藏tool",
    onClick: _cache[48] || (_cache[48] = $event => $options.mgo('setBarVisible', {
      'visible': 0,
      'pos': 2
    }))
  }), _createVNode(_component_van_cell, {
    title: "setBarVisible",
    value: "可见tool",
    onClick: _cache[49] || (_cache[49] = $event => $options.mgo('setBarVisible', {
      'visible': 1,
      'pos': 2
    }))
  }), _createVNode(_component_van_cell, {
    title: "setBarVisible",
    value: "隐藏",
    onClick: _cache[50] || (_cache[50] = $event => $options.mgo('setBarVisible', {
      'visible': 0,
      'pos': 3
    }))
  }), _createVNode(_component_van_cell, {
    title: "setBarVisible",
    value: "可见",
    onClick: _cache[51] || (_cache[51] = $event => $options.mgo('setBarVisible', {
      'visible': 1,
      'pos': 3
    }))
  }), _createVNode(_component_van_cell, {
    title: "removeCtr",
    value: "本页面无测试",
    onClick: _cache[52] || (_cache[52] = $event => $options.domgo('removeCtr', {
      'name': ['控制器name']
    })),
    label: ":::::::移除控制器"
  }), _createVNode(_component_van_cell, {
    title: "sysconfig",
    value: "获取内容",
    onClick: _cache[53] || (_cache[53] = $event => $options.domgo('sysconfig', {})),
    label: ":::::::系统设置"
  }), _createVNode(_component_van_cell, {
    title: "sysconfig",
    value: "en英文",
    onClick: _cache[54] || (_cache[54] = $event => $options.domgo('sysconfig', {
      'lan': 'en'
    })),
    label: ""
  }), _createVNode(_component_van_cell, {
    title: "sysconfig",
    value: "zh中文",
    onClick: _cache[55] || (_cache[55] = $event => $options.domgo('sysconfig', {
      'lan': 'zh'
    })),
    label: ""
  }), _createVNode(_component_van_cell, {
    title: "sysconfig",
    value: "开启震动、声音、角标",
    onClick: _cache[56] || (_cache[56] = $event => $options.domgo('sysconfig', {
      'shock': 1,
      'sound': 1,
      'badge': 1
    })),
    label: ""
  }), _createVNode(_component_van_cell, {
    title: "sysconfig",
    value: "关闭震动、声音、角标",
    onClick: _cache[57] || (_cache[57] = $event => $options.domgo('sysconfig', {
      'shock': 0,
      'sound': 0,
      'badge': 0
    })),
    label: ""
  }), _createVNode(_component_van_cell, {
    title: "showAdr",
    value: "zh",
    onClick: _cache[58] || (_cache[58] = $event => $options.domgo('showAdr', {
      'lat': 23.120776,
      'lon': 113.328123,
      'title': '地址的title',
      'des': '地址的描述',
      'icon': 'http://common-sourcefile.oss-accelerate.aliyuncs.com/tempfile/2022/09/jpg/49a0db6e3a074d2807218a464f6840c3.jpg'
    })),
    label: ":::::::显示地图"
  }), _createVNode(_component_van_cell, {
    title: "scheme",
    value: "alipay",
    onClick: _cache[59] || (_cache[59] = $event => $options.mgo_scheme()),
    label: ":::::::打开网页"
  }), _createVNode(_component_van_cell, {
    title: "showErr",
    onClick: _cache[60] || (_cache[60] = $event => $options.mgo('showErr', {})),
    label: ":::::::错误加载页面"
  }), _createVNode(_component_van_cell, {
    title: "toTop",
    onClick: _cache[61] || (_cache[61] = $event => $options.mgo('toTop')),
    label: ":::::::返回头部"
  }), _createVNode(_component_van_cell, {
    title: "toast",
    onClick: _cache[62] || (_cache[62] = $event => $options.mgo_toast()),
    label: ":::::::toast提示"
  }), _createVNode(_component_van_cell, {
    title: "toast",
    value: "退出",
    onClick: _cache[63] || (_cache[63] = $event => $options.mgo('toast', {
      'out': ''
    }))
  }), _createVNode(_component_van_cell, {
    title: "pickAdr",
    onClick: _cache[64] || (_cache[64] = $event => $options.mgo('pickAdr', {
      'lat': 23.120776,
      'lon': 113.328123,
      'icon': 'http://common-sourcefile.oss-accelerate.aliyuncs.com/tempfile/2022/09/jpg/49a0db6e3a074d2807218a464f6840c3.jpg'
    })),
    label: ":::::::选择地图"
  }), _createVNode(_component_van_cell, {
    title: "pickType",
    onClick: _cache[65] || (_cache[65] = $event => $options.mgo_pickType()),
    label: ":::::::选择类型"
  }), _createVNode(_component_van_cell, {
    title: "pgc",
    value: "vip_m",
    onClick: _cache[66] || (_cache[66] = $event => $options.mgo('pgc', {
      'id': 'vip_m',
      'js': 'calljs'
    })),
    label: ":::::::苹果充值"
  }), _createVNode(_component_van_cell, {
    title: "popmsg",
    onClick: _cache[67] || (_cache[67] = $event => $options.mgo_popmsg()),
    label: ":::::::弹出选项"
  }), _createVNode(_component_van_cell, {
    title: "profileNeed",
    value: "",
    onClick: _cache[68] || (_cache[68] = $event => $options.mgo('profileNeed', {
      'title': '联系前请先填写基本资料',
      'bt1': '填写',
      'bt0': '取消'
    })),
    label: ":::::::填写资料"
  }), _createVNode(_component_van_cell, {
    title: "upload_photo",
    value: "",
    onClick: _cache[69] || (_cache[69] = $event => $options.mgo('upload_photo', {
      'square': 0
    })),
    label: ":::::::上传图片"
  }), _createVNode(_component_van_cell, {
    title: "upload_photo",
    value: "头像",
    onClick: _cache[70] || (_cache[70] = $event => $options.mgo('upload_photo', {
      'square': 1
    }))
  }), _createVNode(_component_van_cell, {
    title: "upload_video",
    value: "",
    onClick: _cache[71] || (_cache[71] = $event => $options.mgo('upload_video', {
      'js': 'calljs'
    })),
    label: ":::::::上传视频"
  }), _createVNode(_component_van_cell, {
    title: "upload_video",
    onClick: _cache[72] || (_cache[72] = $event => $options.mgo_refresh())
  }), _createVNode(_component_van_cell, {
    checked: "",
    title: "alert_confirm",
    onClick: _cache[73] || (_cache[73] = $event => $options.mgo_alert_confirm())
  }), _createVNode(_component_van_cell, {
    checked: "",
    title: "alert_confirm",
    value: "appStyle",
    onClick: _cache[74] || (_cache[74] = $event => $options.mgo_alert_confirm2())
  }), _createVNode(_component_van_cell, {
    title: "pop_option",
    onClick: _cache[75] || (_cache[75] = $event => $options.mgo_refresh())
  }), _createVNode(_component_van_cell, {
    title: "pop_header",
    onClick: _cache[76] || (_cache[76] = $event => $options.mgo_refresh())
  }), _createVNode(_component_van_cell, {
    checked: "",
    title: "alert_popup",
    onClick: _cache[77] || (_cache[77] = $event => $options.mgo_alert_popup())
  }), _createVNode(_component_van_cell, {
    checked: "",
    title: "alert_popmsg",
    value: "dark",
    onClick: _cache[78] || (_cache[78] = $event => $options.mgo_alert_popmsg2())
  })], 64);
}