
import { v4 as uuidv4 } from 'uuid';
import dsb from "@/lib/dsbridge"

var Fly=require("flyio/dist/npm/fly")
var adapter = require("flyio/dist/npm/adapter/dsbridge")
var EngineWrapper = require("flyio/dist/npm/engine-wrapper")
var dsEngine = EngineWrapper(adapter)



adapter = function (request, responseCallBack) {
    dsb.dsBridge.call("onAjaxRequest", request, function onResponse(responseData) {
        responseCallBack(JSON.parse(responseData))
    })
}

var handlerList = [null,null]


function getHandler(index = 0) {
    index = Math.max(0, Math.min(1, index))
    if (handlerList[index] == null) {
        handlerList[index] = setupHandler(index == 0)
    }

    
    return handlerList[index]
}


function setupHandler(isDSB = true) {
    var fly
    if (isDSB && 1==dsb.dsBridge.hasNativeMethod('onAjaxRequest')){
        fly = new Fly(dsEngine);
    }else{
        fly = new Fly();
    }

    fly.config.timeout = 60000;
    if("development"==process.env.NODE_ENV){
        fly.config.baseURL =  window.location.protocol + "//"+process.env.VUE_APP_API_HOST+"/"
    }else{
        var domainArr = window.location.hostname.toLocaleLowerCase().split('.');
        fly.config.baseURL =  window.location.protocol + "//"+"api."+domainArr[domainArr.length-2]+"."+domainArr[domainArr.length-1]+"/"
    }
    // 添加请求拦截器
    fly.interceptors.request.use((request) => {
        var uuid = uuidv4();
        request.headers["Requested-id"]=uuid;
        return request;
    });

    //返回结构
    fly.interceptors.response.use(
        (response) => {
            //只将请求结果的data字段返回
            //console.log(response.data)
            
            return response
        },
        (err) => {
            //发生网络错误后会走到这里
            console.log("err",err)
        }
    )
    
    return fly
}




















//返回字典
export function request(url='',params={},type='POST',isDSB = true){
    return new Promise((resolve,reject)=>{

        var handler = getHandler(isDSB?0:1)

        let promise
        if( type.toUpperCase()==='GET' ){
            promise = handler.get(url,params)
        }else if( type.toUpperCase()==='POST' ){
            promise = handler.post(url,params)
        }
        
        //处理返回
        promise.then(res=>{
            res.data = JSON.parse(res.data)
            res.msg = res.data.msg
            if(res.msg==""){
                res.msg = res.data.result.msg
                
            }
            res.res = res.data.result
            res.code = res.data.code
            if(res.code==0){
                res.code = res.data.result.errcode
            }
            resolve(res)
        }).catch(err=>{
            reject(err)
        })
    })
}



