import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f6f6c06"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ind_back"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_image, {
    round: "",
    class: "ind_back_tx",
    width: "1.68rem",
    height: "1.68rem",
    style: {
      "margin-top": "1.8rem",
      "margin-left": "0rem"
    },
    src: $props.avatar
  }, {
    loading: _withCtx(() => [_createVNode(_component_van_loading, {
      type: "spinner",
      size: "20"
    })]),
    _: 1
  }, 8, ["src"])]);
}