export default {
  name: "PriceBlock",
  props: {
    banner: {
      type: String,
      default: ""
    },
    product: {
      type: String,
      default: ""
    },
    des: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  methods: {},
  data: function () {
    return {};
  },
  watch: {}
};