import { onMounted, reactive, toRefs } from 'vue';
import { moo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button } from "vant";
import RadioPicker from "@/components/RadioPicker.vue";
import RichMedia from "@/components/RichMedia.vue";
export default {
  name: "MooServiceInfo",
  components: {
    [Button.name]: Button,
    [RadioPicker.name]: RadioPicker,
    [RichMedia.name]: RichMedia
  },
  methods: {
    clickOption: function (e) {
      this.current = e;
      for (let i in this.product) {
        this.$refs.jg[i].pick = i == e;
      }
    },
    clickAgreement: function (mink) {
      dsb.mgo(mink.e, mink);
    },
    clickButton: function () {
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 40
      });
      dsb.mgo("pgc", {
        "id": this.product[this.current].id,
        "js": "iap_callback"
      });
    },
    clickRestore: function () {
      dsb.mgo("toast", {
        "word": "执行恢复"
      });
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 40
      });
      dsb.mgo("pgc", {
        "id": "",
        "type": "restore",
        "js": "iap_callback"
      });
    },
    clickTherapist: function (i) {
      if (this.therapistGenderFlag[i] == "男") {
        this.pick_therapist = 1;
      } else if (this.therapistGenderFlag[i] == "女") {
        this.pick_therapist = 2;
      } else {
        this.pick_therapist = 3;
      }
      this.getTherapistNearlist();
    },
    clickGender: function (i) {
      if (this.customerGenderFlag[i] == "男") {
        this.pick_gender = 1;
      } else {
        this.pick_gender = 2;
      }
    },
    clickPrior: function (i) {
      this.pick_prior = i;
      this.getTherapistNearlist();
    }
  },
  setup() {
    const route = useRoute();
    var getTherapistNearlist = () => {
      state.therapistNearList = [""];
      moo.therapistNearlist(state.pick_therapist, state.pick_prior, route.query.city).then(res => {
        if (res.res.list) {
          state.therapistNearList = res.res.list.splice(0, 22);
        }
      });
    };
    const state = reactive({
      image: '',
      title: '',
      tuid: '',
      member: false,
      cost: 0,
      costMember: 0,
      minutes: 0,
      process: [],
      customerGenderFlag: [],
      therapistGenderFlag: [],
      explanation: '',
      showExplanation: false,
      showProcess: false,
      orderMink: {},
      memberMink: {},
      pick_therapist: 0,
      pick_gender: 0,
      pick_prior: 0,
      therapist: {},
      therapistNearList: [""],
      loading: true
    });
    dsb.dsBridge.register('toolbarClick', function () {
      dsb.mgo("localstorage", {
        "key": "order_info",
        "save": {
          "pick_therapist": state.pick_therapist,
          "pick_gender": state.pick_gender,
          "pick_prior": state.pick_prior,
          "image": state.image,
          "title": state.title,
          "process": state.process,
          "customerGenderFlag": state.customerGenderFlag,
          "therapistGenderFlag": state.therapistGenderFlag,
          "cost": state.cost,
          "costMember": state.costMember,
          "member": state.member ? 1 : 0,
          "minutes": state.minutes,
          "therapist": state.therapist
        }
      });
      dsb.mink(state.orderMink);
    });
    dsb.dsBridge.register('memberClick', function () {
      dsb.mink(state.memberMink);
    });

    // 调取api获取数据
    const getdata = () => {
      if (route.query.tuid != undefined) {
        state.tuid = route.query.tuid;
      }
      moo.service_info(route.query.id, state.tuid).then(res => {
        if (res.code == 0) {
          state.image = res.res.image;
          state.title = res.res.title;
          state.process = res.res.process.split("、");
          if (res.res.therapistGenderFlag == 1) {
            state.therapistGenderFlag = ['男'];
            state.pick_therapist = 1;
          } else if (res.res.therapistGenderFlag == 2) {
            state.therapistGenderFlag = ['女'];
            state.pick_therapist = 2;
          } else {
            state.therapistGenderFlag = ['不限', '女', '男'];
            state.pick_therapist = 3;
          }
          if (res.res.customerGenderFlag == 1) {
            state.customerGenderFlag = ['男'];
            state.pick_gender = 1;
          } else if (res.res.customerGenderFlag == 2) {
            state.customerGenderFlag = ['女'];
            state.pick_gender = 2;
          } else {
            state.customerGenderFlag = ['女', '男'];
            state.pick_gender = 2;
          }
          state.minutes = res.res.mins;
          state.cost = res.res.price;
          state.costMember = res.res.priceMember;
          state.member = res.res.member == 1;
          state.orderMink = res.res.orderMink;
          state.memberMink = res.res.memberMink;
          state.therapist = res.res.therapist;
          dsb.h5config(res.res.h5);
          state.loading = false;
        }
      });
      getTherapistNearlist();
    };
    state.query = route.query;
    onMounted(getdata);
    return {
      ...toRefs(state),
      getTherapistNearlist
    };
  },
  watch: {}
};