import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue';
import { qb } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button } from "vant";
import CzOption from "./CzOption.vue";
import gsap from 'gsap';
var route;
export default {
  name: "YouthYoQbZs",
  components: {
    [Button.name]: Button,
    'CzOption': CzOption
  },
  methods: {
    clickOption: function (e) {
      this.current = e;
      for (let i in this.product) {
        this.$refs.jg[i].pick = i == e;
      }
    },
    clickAgreement: function (mink) {
      dsb.mgo(mink.e, mink);
    },
    clickButton: function () {
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 40
      });
      dsb.mgo("pgc", {
        "id": this.product[this.current].id,
        "js": "iap_callback"
      });
    },
    clickRestore: function () {
      dsb.mgo("toast", {
        "word": "执行恢复"
      });
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 40
      });
      dsb.mgo("pgc", {
        "id": "",
        "type": "restore",
        "js": "iap_callback"
      });
    }
  },
  setup() {
    route = useRoute();
    const datab = getCurrentInstance();
    const state = reactive({
      product: [],
      current: 0,
      zsNum: 0,
      zd: {},
      agreement: {},
      query: {}
    });
    dsb.dsBridge.register('iap_callback', function (x) {
      dsb.mgo("setLoading", {
        "act": 0
      });
      if (x.status == 0) {
        qb.qbZs(state.query.uid, state.query.token).then(res => {
          if (res.data.code == 0) {
            if (x.type == 0) {
              dsb.mgo("toast", {
                "word": "充值成功"
              });
            }
            datab.data.counter = res.data.result.zs;
          }
        });
      } else if (x.status == -1) {
        dsb.mgo("toast", {
          "word": "操作取消"
        });
      } else {
        dsb.mgo("alert", {
          "title": "提示",
          "word": "充值失败,请稍后重试,或联系客服,错误码：" + x.status,
          "button": "确定"
        });
      }
    });

    // 调取api获取数据
    const getdata = () => {
      qb.qbZs().then(res => {
        if (res.data.code == 0) {
          state.product = res.data.result.product;
          datab.data.showNumber = res.data.result.zs;
          state.agreement = res.data.result.agreement;
          state.zd = res.data.result.zd;
        }
      });
    };
    state.query = route.query;
    onMounted(getdata);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    return {
      ...toRefs(state),
      themeVars
    };
  },
  data() {
    return {
      counter: 0,
      showNumber: 0
    };
  },
  watch: {
    counter(newValue) {
      gsap.to(this, {
        duration: 1,
        showNumber: newValue
      });
    }
  }
};