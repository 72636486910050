//
import { reactive, toRefs } from 'vue';
export default {
  name: 'App',
  components: {},
  method: {},
  setup() {
    const state = reactive({
      longscreen: false,
      screenWidht: 0
    });
    function isBigScreen() {
      //，这里根据返回值 true 或false ,返回true的话 则为全面屏 
      let result = false;
      const rate = window.screen.height / window.screen.width;
      let limit = window.screen.height == window.screen.availHeight ? 1.8 : 1.65; // 临界判断值  
      //window.screen.height为屏幕高度
      //window.screen.availHeight 为浏览器 可用高度
      if (rate > limit) {
        result = true;
      }
      return result;
    }

    //判断是否是手机端
    function isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    }

    // 如果不是移动端则认为是pc端
    if (!isMobile()) {
      state.screenWidht = 690;
      let body = document.querySelector("body");
      body.style.maxWidth = state.screenWidht.toString() + "px";
      body.style.overflow = "hidden";
      body.style.margin = "0 auto";
      body.style.transform = "translate(0)";
      //body.style.height = window.screen.availHeight.toString()+"px";
    }

    //设置rem比例
    (function (doc, win) {
      var docEl = doc.documentElement;
      var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
      var recalc = function () {
        var clientWidth = 0;
        if (state.screenWidht != 0) {
          clientWidth = state.screenWidht;
        } else {
          clientWidth = docEl.clientWidth;
        }
        if (!clientWidth) return;
        docEl.style.fontSize = clientWidth / 7.5 + 'px';
      };
      if (!doc.addEventListener) return;
      win.addEventListener(resizeEvt, recalc, false);
      doc.addEventListener('DOMContentLoaded', recalc, false);
    })(document, window);
    state.longscreen = isBigScreen();
    return {
      ...toRefs(state)
    };
  }
};