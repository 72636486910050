import { onMounted, reactive, toRefs, ref } from 'vue';
import { moo } from "@/api/restful";
import { useRoute } from "vue-router";
import dsb from "@/lib/dsbridge";
import { Button, Field } from "vant";
import OrderInfo from '@/components/moo/OrderInfo.vue';
import { getBRDatePickerData } from "@/lib/tool";
export default {
  name: "MooServiceOrder",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [OrderInfo.name]: OrderInfo
  },
  methods: {
    bulrField: function (t) {
      switch (t) {
        case "detail":
          this.address_detail = this.address_detail.trim();
          break;
        case "name":
          this.address_name = this.address_name.trim();
          break;
        case "phone":
          this.address_phone = this.address_phone.trim();
          break;
        case "remark":
          this.remark = this.remark.trim();
          break;
      }
      dsb.mgo("localstorage", {
        "key": "address_info",
        "save": {
          "detail": this.address_detail,
          "name": this.address_name,
          "phone": this.address_phone,
          "area": this.address_area,
          "pos": this.address_pos,
          "remark": this.remark
        }
      });
    },
    pickPos: function () {
      var instance = this;
      var res = dsb.mgo("lbs_info", {});
      if (res) {
        if (res.status == 1) {
          if (res.res.lbs == 0) {
            dsb.mgo("lbs_request", {
              "title": "提示",
              "word": "需要在设置中打开位置",
              "bt0": "取消",
              "bt1": "设置"
            }, "", function () {});
          } else {
            dsb.mgo("pickAdr", {
              "lat": res.res.lat,
              "lon": res.res.lon,
              "type": "住宿服务|商务住宅|公司企业"
            }, "", function (v) {
              var res = JSON.parse(v);
              instance.address_area = res.name;
              instance.address_pos = res;
              instance.bulrField("area");
            });
          }
        }
      }
    },
    selectServerTime: function () {
      var instance = this;
      var list = getBRDatePickerData(2, 60 * 60);
      dsb.mgo("picker", {
        "list": list,
        "row": 3,
        "default": this.order_timePickPos
      }, "", function (v) {
        var res = JSON.parse(v);
        var date = new Date(res[2].key.replaceAll("-", "/"));
        instance.order_timeStr = res[0].value + "," + date.getHours() + "点" + date.getMinutes() + "分";
        instance.order_time = Math.floor(date.getTime() / 1000);
        instance.order_timePickPos = [res[0].index, res[1].index, res[2].index];
      });
    },
    selectOrderUntil: function () {
      var instance = this;
      dsb.mgo("select", {
        "content": ["30分钟", "60分钟", "2小时", "直到服务时间前"],
        "default": instance.order_untilStr
      }, "", function (v) {
        var res = JSON.parse(v);
        var timestamp = Math.floor(Date.parse(new Date()) / 1000);
        switch (res.str) {
          case "30分钟":
            instance.order_untilTime = timestamp + 30 * 60;
            break;
          case "60分钟":
            instance.order_untilTime = timestamp + 60 * 60;
            break;
          case "2小时":
            instance.order_untilTime = timestamp + 2 * 60 * 60;
            break;
          default:
            instance.order_untilTime = 0;
            break;
        }
        instance.order_untilStr = res.str;
      });
    }
  },
  setup() {
    document.body.style.backgroundColor = "#f2f5f7";
    const route = useRoute();
    const state = reactive({
      address_pos: {},
      address_area: "",
      address_detail: "",
      address_name: "",
      address_phone: "",
      member: 0,
      discount: "没有优惠可以使用",
      order_timeStr: "",
      order_timePickPos: [0, 0, 0],
      order_time: 0,
      order_untilStr: "直到服务时间前",
      order_untilTime: 0,
      remark: "",
      serviceId: "",
      service_image: "",
      service_cost: 0,
      service_name: "",
      service_minutes: 0,
      pick_therapist: 0,
      pick_gender: 0,
      pick_prior: 0,
      pick_prior_str: "",
      therapist: {},
      tour_fee: false
    });
    dsb.dsBridge.register('toolbarClick', function () {
      var address = {
        "detail": state.address_detail,
        "name": state.address_name,
        "phone": state.address_phone,
        "area": state.address_area,
        "pos": state.address_pos,
        "remark": state.remark
      };
      dsb.mgo("localstorage", {
        "key": "address_info",
        "save": address
      });
      var msg = "";
      if (state.address_name == "") {
        msg = "请输入姓名";
      } else if (state.address_phone == "") {
        msg = "请输入手机号";
      } else if (state.address_phone.length != 11) {
        msg = "请输入正确的手机号";
      } else if (state.address_area == "") {
        msg = "请选择地址";
      } else if (state.address_detail == "") {
        msg = "请输入详细地址";
      }
      if (msg != "") {
        dsb.mgo("toast", {
          "word": msg
        });
        return;
      }
      dsb.mgo("setLoading", {
        "act": 1,
        "timeout": 10
      });
      moo.order_create(state.serviceId, state.therapist.uid ? state.therapist.uid : "", address, state.pick_gender, state.pick_therapist, state.pick_prior, state.order_time, state.order_untilTime, state.remark).then(res => {
        if (res.code == 0) {
          console.log(res.res.mink);
          dsb.mink(res.res.mink);
          dsb.mgo("mustRefresh", {
            "index": 0
          });
          dsb.mgo("setLoading", {
            "act": 0
          });
        }
      });
    });

    // 调取api获取数据
    const getdata = () => {
      var timelist = getBRDatePickerData(2, 60 * 60);
      for (let i = 0; i < timelist.length; ++i) {
        if (timelist[i].level == 0 && timelist[i].sort == 0) {
          state.order_timeStr = timelist[i].value;
        }
        if (timelist[i].level == 2 && timelist[i].sort == 0) {
          var date = new Date(timelist[i].key.replaceAll("-", "/"));
          state.order_timeStr += "," + date.getHours() + "点" + date.getMinutes() + "分";
          state.order_time = Math.floor(date.getTime() / 1000);
        }
      }
      var adrinfo = dsb.mgo("localstorage", {
        "key": "address_info",
        "get": 1
      });
      if (adrinfo.res.get != null) {
        state.address_area = adrinfo.res.get.area;
        state.address_detail = adrinfo.res.get.detail;
        state.address_name = adrinfo.res.get.name;
        state.address_phone = adrinfo.res.get.phone;
        state.address_pos = adrinfo.res.get.pos;
        state.remark = adrinfo.res.get.remark;
      }
      var pickinfo = dsb.mgo("localstorage", {
        "key": "order_info",
        "get": 1
      });
      state.pick_therapist = pickinfo.res.get.pick_therapist;
      state.pick_gender = pickinfo.res.get.pick_gender;
      state.pick_prior = pickinfo.res.get.pick_prior;
      state.service_image = pickinfo.res.get.image;
      state.service_name = pickinfo.res.get.title;
      state.service_minutes = pickinfo.res.get.minutes;
      state.service_cost = pickinfo.res.get.cost;
      state.member = pickinfo.res.get.member;
      state.therapist = pickinfo.res.get.therapist;
      if (state.member == 1) {
        state.discount = "会员9折优惠";
        state.service_cost = pickinfo.res.get.costMember;
      }
      var tmpList = ['派单给附近技师', '优先派单给预约技师', '仅派单给预约技师'];
      state.pick_prior_str = tmpList[state.pick_prior];
      state.serviceId = route.query.id;
      moo.service_order(state.serviceId).then(res => {
        if (res.code == 0) {
          dsb.h5config(res.res.h5);
        }
      });
    };
    state.query = route.query;
    onMounted(getdata);
    const themeVars = {
      appGender: route.query.gender == 2 ? 'var(--van-app-female)' : 'var(--van-app-male)'
    };
    const activeName = ref('0');
    return {
      ...toRefs(state),
      themeVars,
      activeName
    };
  }
};