import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c43bdfe8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "display": "flex",
    "flex-direction": "row"
  }
};
const _hoisted_2 = {
  class: "image"
};
const _hoisted_3 = {
  class: "info"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "product"
};
const _hoisted_6 = {
  class: "titleRight"
};
const _hoisted_7 = {
  class: "cost"
};
const _hoisted_8 = {
  class: "duration"
};
const _hoisted_9 = {
  class: "des"
};
const _hoisted_10 = {
  class: "des"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_image = _resolveComponent("van-image");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_image, {
    src: $props.image,
    style: {
      "height": "100%",
      "width": "100%"
    },
    fit: "cover"
  }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, _toDisplayString($props.title), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString($props.cost) + "元", 1), _createElementVNode("p", _hoisted_8, _toDisplayString($props.duration) + "分钟", 1)])]), _createElementVNode("p", _hoisted_9, _toDisplayString($props.therapistGenderFlag == 1 ? "男技师" : $props.therapistGenderFlag == 2 ? "女技师" : "技师不限"), 1), _createElementVNode("p", _hoisted_10, _toDisplayString($props.gender == 1 ? "男顾客" : "女顾客"), 1)])]);
}