import "core-js/modules/es.array.unshift.js";
import dsb from "@/lib/dsbridge";
import { photoData } from "@/api/restful";
import { useRoute } from "vue-router";
var route;
export default {
  name: 'upload-cross',
  props: {
    limit: {
      typeof: Number,
      default: 99
    },
    data: {
      typeof: Array,
      default: []
    },
    manager: {
      typeof: Boolean,
      default: false
    }
  },
  methods: {
    remove(index) {
      if (this.manager) {
        photoData.photoRemove(route.query.uid, route.query.token, this.list[index].photoId);
      }
      this.list.splice(index, 1);
    },
    upload() {
      var instance = this;
      dsb.dsBridge.register('uploadCompletion', function (res) {
        if (instance.manager) {
          photoData.photoAdd(route.query.uid, route.query.token, res.url).then(info => {
            if (info.data.code == 0) {
              for (var photoId in info.data.result.dict) {
                for (var i = 0; i < instance.list.length; i++) {
                  if (instance.list[i].url == info.data.result.dict[photoId]) {
                    instance.list[i].status = 0;
                    instance.list[i].photoId = photoId;
                    break;
                  }
                }
              }
            }
          });
        }
      });
      dsb.mgo("upload_photo", {
        "js": "uploadCompletion",
        "square": 0,
        "count": Math.min(Math.max(this.limit - this.list.length, 0), 9)
      }, "", function (v) {
        var res = JSON.parse(v);
        var photolist = [];
        var base64list = [];
        photolist = res.url;
        base64list = res.base64;
        var randomnum = new Date().getTime() * 10;
        for (var i = 0; i < photolist.length; i++) {
          var tempData = {
            "photoId": (randomnum + i).toString(),
            "url": photolist[i],
            "status": -1,
            "color": "f1f1f1"
          };
          if (base64list) {
            tempData["base64"] = base64list[i];
          }
          instance.list.unshift(tempData);
        }
      });
    }
  },
  setup() {
    route = useRoute();
  },
  data: function () {
    return {
      list: this.data
    };
  },
  watch: {
    list(nv) {
      this.$emit('update:data', nv);
    }
  }
};